import { createContext } from 'react';
import { AutoCompounder } from '../../tomb-finance';

export interface AutoCompoundersContext {
  autoCompounders: AutoCompounder[];
}

const context = createContext<AutoCompoundersContext>({
  autoCompounders: [],
});

export default context;
