import React, { useCallback, useEffect, useState } from 'react';
import Context from './context';
import useTombFinance from '../../hooks/useTombFinance';
import { LendingPool } from '../../tomb-finance';
import config, { lendingPoolDefinitions } from '../../config';

const LendingPools: React.FC = ({ children }) => {
  const [lendingPools, setLendingPools] = useState<LendingPool[]>([]);
  const tombFinance = useTombFinance();
  const isUnlocked = tombFinance?.isUnlocked;

  const fetchPools = useCallback(async () => {
    const lendingPools: LendingPool[] = [];

    for (const bankInfo of Object.values(lendingPoolDefinitions)) {
      if (bankInfo.finished) {
        if (!tombFinance.isUnlocked) continue;

        // Show only show pools staked by user
        const balance = await tombFinance.stakedBalanceOnAutoCompounder(
          bankInfo.vaultContract,
          tombFinance.myAccount,
        );
        if (balance.lte(0)) {
          continue;
        }
      }
      lendingPools.push({
        ...bankInfo,
        address: config.deployments[bankInfo.contract].address,
        depositToken: tombFinance.externalTokens[bankInfo.depositTokenName],
        pairTokenContract1: bankInfo.pairTokenName1 === 'DOLLAR' || bankInfo.pairTokenName1 === 'GOLD' ? (bankInfo.pairTokenName1 === 'DOLLAR' ? tombFinance.DOLLAR : tombFinance.GOLD) : tombFinance.externalTokens[bankInfo.pairTokenName1],
        pairTokenContract2: bankInfo.pairTokenName2 === 'DOLLAR' || bankInfo.pairTokenName2 === 'GOLD' ? (bankInfo.pairTokenName2 === 'DOLLAR' ? tombFinance.DOLLAR : tombFinance.GOLD) : tombFinance.externalTokens[bankInfo.pairTokenName2],
        earnToken: bankInfo.earnTokenName === 'DOLLAR' || bankInfo.earnTokenName === 'DOLLAR' ? (bankInfo.earnTokenName === 'DOLLAR' ? tombFinance.DOLLAR : tombFinance.GOLD) : tombFinance.externalTokens[bankInfo.earnTokenName],
      });
    }
    lendingPools.sort((a, b) => (a.sort > b.sort ? 1 : -1));
    setLendingPools(lendingPools);
  }, [tombFinance, setLendingPools]);

  useEffect(() => {
    if (tombFinance) {
      fetchPools().catch((err) => console.error(`Failed to fetch lendingPools: ${err.stack}`));
    }
  }, [isUnlocked, tombFinance, fetchPools]);

  return <Context.Provider value={{ lendingPools }}>{children}</Context.Provider>;
};

export default LendingPools;
