import React from 'react';
import Typography from '@material-ui/core/Typography';

const Loader = () => {
  return (
    <div
      style={{
        position: 'absolute',
        height: '100vh',
        width: '100vw',
        background: 'rgba(0, 0, 0, 1)',
      }}
    >
      <p
      style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      >Loading</p>
    </div>
  );
};

export default Loader;
