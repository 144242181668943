function setPlayingNft(playingNft) {
    localStorage.setItem('playing-nft', playingNft);
    document.documentElement.className = playingNft;
}

function keepPlayingNft() {
  if (localStorage.getItem('playing-nft')) {
  //   if (localStorage.getItem('playing-nft') === 'playing-nft-1') {
  //     setPlayingNft('playing-nft-1');
  //   }
  //   if (localStorage.getItem('playing-nft') === 'playing-nft-2') {
  //     setPlayingNft('playing-nft-2')
  //   }
  //   if (localStorage.getItem('playing-nft') === 'playing-nft-3') {
  //     setPlayingNft('playing-nft-3')
  //   }
  } else {
    setPlayingNft('NONE')
  }
}

module.exports = {
  setPlayingNft,
  keepPlayingNft
}