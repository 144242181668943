import { createContext } from 'react';
import { Casino } from '../../tomb-finance';

export interface CasinosContext {
  casinos: Casino[];
}

const context = createContext<CasinosContext>({
  casinos: [],
});

export default context;
