import { Fetcher as FetcherSpirit, Route, Token as TokenSpirit } from '@spiritswap/sdk';
import { Fetcher as FetcherSpooky, Route as RouteSpooky, Token as TokenSpooky /*, Route as RouteSpooky*/ } from '@spookyswap/sdk'
import { Configuration } from './config';
import { ContractName, TokenStat, AllocationTime, LPStat, Bank, PoolStats, TShareSwapperStat, AutocompounderStats, AutoCompounder, SinSpiritStats, PredictionsRewards, PredictionsStats, AccountInfo, PredictionsRewardsV2, NileRiverStats, ProfitDistributionInfo, NftInfo, MummyNftStatsUser } from './types';
import { BigNumber, Contract, ethers, EventFilter } from 'ethers';
import { decimalToBalance } from './ether-utils';
import { TransactionResponse } from '@ethersproject/providers';
import ERC20 from './ERC20';
import { getFullDisplayBalance, getDisplayBalance } from '../utils/formatBalance';
import { getDefaultProvider } from '../utils/provider';
import IUniswapV2PairABI from './IUniswapV2Pair.abi.json';
import config, { bankDefinitions } from '../config';
import moment from 'moment';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { FTM_TICKER, SPIRIT_ROUTER_ADDR, DOLLAR_TICKER } from '../utils/constants';
/**
 * An API module of Memphis Finance contracts.
 * All contract-interacting domain logic should be defined in here.
 */
export class TombFinance {
    myAccount: string;
    provider: ethers.providers.Web3Provider;
    signer?: ethers.Signer;
    config: Configuration;
    contracts: { [name: string]: Contract };
    externalTokens: { [name: string]: ERC20 };
    masonryVersionOfUser?: string;

    DOLLARWFTM_LP: Contract;
    TOMBFTMLP: Contract;
    TSHAREFTMLP: Contract;
    SPIRITFTMFTMLP: Contract;
    LQDRFTMLP: Contract;
    CREATORFTMLP: Contract;
    SPIRITSINSPIRITLP: Contract;
    fUSDTFTMLP: Contract;
    USDCFTMLP: Contract;
    BTCFTMLP: Contract;
    ETHFTMLP: Contract;
    FRAXFTMLP: Contract;
    MAIFTMLP: Contract;
    BOMBFTMLP: Contract;
    BIFIFTMLP: Contract;
    DEIUSDCLP: Contract;
    gOHMFTMLP: Contract;
    ATLASFTMLP: Contract;
    DOLLARGOLDLP: Contract;
    DOLLARUSDCLP: Contract;
    DOLLARETHLP: Contract;
    GOLDUSDCLP: Contract;
    GOLDFTMLP: Contract;
    DEUSFTMLP: Contract;
    TOMB: ERC20;
    TSHARE: ERC20;
    DOLLAR: ERC20;
    GOLD: ERC20;
    PAPER: ERC20;
    FTM: ERC20;
    LQDR: ERC20;
    CREATOR: ERC20;
    SPIRIT: ERC20;
    fUSDT: ERC20;
    USDC: ERC20;
    BTC: ERC20;
    ETH: ERC20;
    FRAX: ERC20;
    MAI: ERC20;
    SCEPTRE: ERC20;
    GSCEPTRE: ERC20;
    oSCEPTRE: ERC20;

    BOAT: ERC20;
    BOMB: ERC20;
    BIFI: ERC20;
    DAI: ERC20;
    DEI: ERC20;
    ATLAS: ERC20;
    gOHM: ERC20;
    DEUS: ERC20;


    constructor(cfg: Configuration) {
        const { deployments, externalTokens } = cfg;
        const provider = getDefaultProvider();

        // loads contracts from deployments
        this.contracts = {};
        for (const [name, deployment] of Object.entries(deployments)) {
            this.contracts[name] = new Contract(deployment.address, deployment.abi, provider);
        }
        this.externalTokens = {};
        for (const [symbol, [address, decimal]] of Object.entries(externalTokens)) {
            this.externalTokens[symbol] = new ERC20(address, provider, symbol, decimal);
        }
        this.DOLLAR = new ERC20(deployments.tomb.address, provider, 'DOLLAR');
        this.GOLD = new ERC20(deployments.tShare.address, provider, 'GOLD');
        this.PAPER = new ERC20(deployments.tBond.address, provider, 'PAPER');
        this.SCEPTRE = new ERC20(deployments.tomb.address, provider, 'SCEPTRE');
        this.GSCEPTRE = new ERC20(deployments.tShare.address, provider, 'GSCEPTRE');
        this.oSCEPTRE = new ERC20(deployments.tBond.address, provider, 'oSCEPTRE');
        this.FTM = this.externalTokens['WFTM'];
        this.TOMB = this.externalTokens['TOMB'];
        this.TSHARE = this.externalTokens['TSHARE'];
        this.LQDR = this.externalTokens['LQDR'];
        this.CREATOR = this.externalTokens['CRE8R'];
        this.SPIRIT = this.externalTokens['SPIRIT'];
        this.fUSDT = this.externalTokens['fUSDT'];
        this.USDC = this.externalTokens['USDC'];
        this.BTC = this.externalTokens['BTC'];
        this.ETH = this.externalTokens['ETH'];
        this.FRAX = this.externalTokens['FRAX'];
        this.MAI = this.externalTokens['MAI'];

        this.BOAT = this.externalTokens['BOAT'];
        this.BOMB = this.externalTokens['BOMB'];
        this.BIFI = this.externalTokens['BIFI'];
        this.DAI = this.externalTokens['DAI'];
        this.DEI = this.externalTokens['DEI'];
        this.ATLAS = this.externalTokens['ATLAS'];
        this.gOHM = this.externalTokens['gOHM'];
        this.DEUS = this.externalTokens['DEUS'];


        // Uniswap V2 Pair
        this.DOLLARWFTM_LP = new Contract(externalTokens['DOLLAR-TOMB-LP'][0], IUniswapV2PairABI, provider);
        this.DOLLARUSDCLP = new Contract(externalTokens['DOLLAR-USDC-LP'][0], IUniswapV2PairABI, provider);
        this.GOLDUSDCLP = new Contract(externalTokens['GOLD-USDC-LP'][0], IUniswapV2PairABI, provider);
        this.GOLDFTMLP = new Contract(externalTokens['GOLD-FTM-LP'][0], IUniswapV2PairABI, provider);
        this.TOMBFTMLP = new Contract(externalTokens['TOMB-FTM-LP'][0], IUniswapV2PairABI, provider);
        this.TSHAREFTMLP = new Contract(externalTokens['TSHARE-FTM-LP'][0], IUniswapV2PairABI, provider);
        this.LQDRFTMLP = new Contract(externalTokens['LQDR-FTM-LP'][0], IUniswapV2PairABI, provider);
        this.CREATORFTMLP = new Contract(externalTokens['CRE8R-FTM-LP'][0], IUniswapV2PairABI, provider);
        this.SPIRITFTMFTMLP = new Contract(externalTokens['SPIRIT-FTM-LP'][0], IUniswapV2PairABI, provider);
        this.fUSDTFTMLP = new Contract(externalTokens['fUSDT-FTM-LP'][0], IUniswapV2PairABI, provider);
        this.USDCFTMLP = new Contract(externalTokens['USDC-FTM-LP'][0], IUniswapV2PairABI, provider);
        this.BTCFTMLP = new Contract(externalTokens['BTC-FTM-LP'][0], IUniswapV2PairABI, provider);
        this.ETHFTMLP = new Contract(externalTokens['ETH-FTM-LP'][0], IUniswapV2PairABI, provider);
        this.FRAXFTMLP = new Contract(externalTokens['FRAX-FTM-LP'][0], IUniswapV2PairABI, provider);
        this.MAIFTMLP = new Contract(externalTokens['MAI-FTM-LP'][0], IUniswapV2PairABI, provider);
        this.SPIRITSINSPIRITLP = new Contract(externalTokens['SPIRIT-SINSPIRIT-LP'][0], IUniswapV2PairABI, provider);

        this.BOMBFTMLP = new Contract(externalTokens['BOMB-FTM-LP'][0], IUniswapV2PairABI, provider);
        this.BIFIFTMLP = new Contract(externalTokens['BIFI-FTM-LP'][0], IUniswapV2PairABI, provider);
        this.DEIUSDCLP = new Contract(externalTokens['DEI-USDC-LP'][0], IUniswapV2PairABI, provider);
        this.gOHMFTMLP = new Contract(externalTokens['gOHM-FTM-LP'][0], IUniswapV2PairABI, provider);
        this.ATLASFTMLP = new Contract(externalTokens['ATLAS-FTM-LP'][0], IUniswapV2PairABI, provider);
        this.DOLLARGOLDLP = new Contract(externalTokens['DOLLAR-GOLD-LP'][0], IUniswapV2PairABI, provider);
        this.DOLLARETHLP = new Contract(externalTokens['DOLLAR-ETH-LP'][0], IUniswapV2PairABI, provider);
        this.DEUSFTMLP = new Contract(externalTokens['DEUS-FTM-LP'][0], IUniswapV2PairABI, provider);

        this.config = cfg;
        this.provider = provider;
    }

    /**
     * @param provider From an unlocked wallet. (e.g. Metamask)
     * @param account An address of unlocked wallet account.
     */
    unlockWallet(provider: any, account: string) {
        const newProvider = new ethers.providers.Web3Provider(provider, this.config.chainId);
        this.signer = newProvider.getSigner(0);
        this.myAccount = account;
        for (const [name, contract] of Object.entries(this.contracts)) {
            this.contracts[name] = contract.connect(this.signer);
        }
        const tokens = [this.DOLLAR, this.GOLD, this.PAPER, this.SCEPTRE, this.GSCEPTRE, this.oSCEPTRE, ...Object.values(this.externalTokens)];
        for (const token of tokens) {
            token.connect(this.signer);
        }
        // this.DOLLARWFTM_LP = this.DOLLARWFTM_LP.connect(this.signer);
        this.DOLLARUSDCLP = this.DOLLARUSDCLP.connect(this.signer);
        console.log(`🔓 Wallet is unlocked. Welcome, ${account}!`);
        this.fetchMasonryVersionOfUser()
            .then((version) => (this.masonryVersionOfUser = version))
            .catch((err) => {
                console.error(`Failed to fetch temple version: ${err.stack}`);
                this.masonryVersionOfUser = 'latest';
            });
    }

    get isUnlocked(): boolean {
        return !!this.myAccount;
    }

    //===================================================================
    //===================== GET ASSET STATS =============================
    //===================FROM SPOOKY TO DISPLAY =========================
    //=========================IN HOME PAGE==============================
    //===================================================================

    async getExternalTokenPrice(tokenName: string): Promise<TokenStat> {
        // if (tokenName === "SinSPIRIT") {
        //   return {
        //     tokenInFtm: '0',
        //     priceInDollars: '0',
        //     totalSupply: '0',
        //     circulatingSupply: '0',
        //   };
        // }

        if (tokenName === "axlUSDC") {
            const priceInFTM = await this.getTokenPriceFromSpookySwap(this.externalTokens[tokenName]);
            const priceOfOneFTM = await this.getWFTMPriceFromPancakeswap();
            const priceOfTombInDollars = (Number(priceInFTM) * Number(priceOfOneFTM)).toFixed(2);

            return {
                tokenInFtm: priceInFTM,
                priceInDollars: priceOfTombInDollars,
                totalSupply: '0',
                circulatingSupply: '0',
            };
        }

        if (tokenName === "SinSPIRIT") {
            const priceInFTM = await this.getTokenPriceFromSpiritswap(this.externalTokens[tokenName]);
            const priceOfOneFTM = await this.getWFTMPriceFromPancakeswap();
            const priceOfTombInDollars = (Number(priceInFTM) * Number(priceOfOneFTM)).toFixed(2);

            return {
                tokenInFtm: priceInFTM,
                priceInDollars: priceOfTombInDollars,
                totalSupply: '0',
                circulatingSupply: '0',
            };
        }

        if (tokenName === "gOHM") {
            const priceInFTM = await this.getTokenPriceFromSpookySwap(this.externalTokens[tokenName]);
            const priceOfOneFTM = await this.getWFTMPriceFromPancakeswap();
            const priceOfTombInDollars = (Number(priceInFTM) * Number(priceOfOneFTM)).toFixed(2);

            return {
                tokenInFtm: priceInFTM,
                priceInDollars: priceOfTombInDollars,
                totalSupply: '0',
                circulatingSupply: '0',
            };
        }

        if (tokenName === "CRE8R" || tokenName === "LQDR" || tokenName === "fUSDT" || tokenName === "USDC" || tokenName === "BTC" || tokenName === "ETH" || tokenName === "FRAX" || tokenName === "MAI" || tokenName === "BOMB" || tokenName === "BIFI" || tokenName === "DEI" || tokenName === "DAI" || tokenName === "ATLAS" || tokenName === "DEUS" || tokenName === "BASED" || tokenName === "BOO") {
            const priceInFTM = await this.getTokenPriceFromPancakeswap(this.externalTokens[tokenName]);
            const priceOfOneFTM = await this.getWFTMPriceFromPancakeswap();
            const priceOfTombInDollars = (Number(priceInFTM) * Number(priceOfOneFTM)).toFixed(2);

            return {
                tokenInFtm: priceInFTM,
                priceInDollars: priceOfTombInDollars,
                totalSupply: '0',
                circulatingSupply: '0',
            };
        }

        if (tokenName === "BOAT") {
            return {
                tokenInFtm: '0',
                priceInDollars: '0',
                totalSupply: '0',
                circulatingSupply: '0',
            };
        }

        if (tokenName === "SPIRIT") {
            const priceInFTM = await this.getTokenPriceFromSpiritswap(this.externalTokens[tokenName]);
            const priceOfOneFTM = await this.getWFTMPriceFromPancakeswap();
            const priceOfTombInDollars = (Number(priceInFTM) * Number(priceOfOneFTM)).toFixed(2);

            return {
                tokenInFtm: priceInFTM,
                priceInDollars: priceOfTombInDollars,
                totalSupply: '0',
                circulatingSupply: '0',
            };
        }

        if (tokenName === "SPIRIT-FTM-LP" || tokenName === "fUSDT-FTM-LP" || tokenName === "USDC-FTM-LP" || tokenName === "BTC-FTM-LP" || tokenName === "ETH-FTM-LP") {
            const priceInFTM = await this.getTokenPriceFromSpiritswap(this.externalTokens[tokenName]);
            const priceOfOneFTM = await this.getWFTMPriceFromPancakeswap();
            const priceOfTombInDollars = (Number(priceInFTM) * Number(priceOfOneFTM)).toFixed(2);

            return {
                tokenInFtm: priceInFTM,
                priceInDollars: priceOfTombInDollars,
                totalSupply: '0',
                circulatingSupply: '0',
            };
        }

        const priceInFTM = await this.getTokenPriceFromSpookySwap(this.externalTokens[tokenName]);
        const priceOfOneFTM = await this.getWFTMPriceFromPancakeswap();
        const priceOfTombInDollars = (Number(priceInFTM) * Number(priceOfOneFTM)).toFixed(2);

        return {
            tokenInFtm: priceInFTM,
            priceInDollars: priceOfTombInDollars,
            totalSupply: '0',
            circulatingSupply: '0',
        };
    }

    async getTombStat(): Promise<TokenStat> {
        const { DollarTombGenesisRewardPool } = this.contracts;
        const supply = await this.DOLLAR.totalSupply();
        const tombRewardPoolSupply = await this.DOLLAR.balanceOf(DollarTombGenesisRewardPool.address);
        const tombCirculatingSupply = supply
            .sub(tombRewardPoolSupply)
        const priceOfOneAxlUSDC = await this.getExternalTokenPrice("axlUSDC");
        const priceInFTM = await this.getTokenPriceFromSpookySwapInTomb(this.DOLLAR);
        const priceOfOneTombInFtm = await this.getTombPriceFromPancakeswap();
        const priceOfOneFTM = await this.getTombPriceFromPancakeswap();
        const priceOfTombInDollars = ((Number(priceInFTM) * Number(priceOfOneTombInFtm) * Number(priceOfOneFTM)) / Number(priceOfOneAxlUSDC.priceInDollars)).toFixed(2);


        console.log("TOMB INFO", priceInFTM, priceOfTombInDollars)

        return {
            tokenInFtm: priceInFTM,
            priceInDollars: priceOfTombInDollars,
            totalSupply: getDisplayBalance(supply, this.DOLLAR.decimal, 0),
            circulatingSupply: getDisplayBalance(tombCirculatingSupply, this.DOLLAR.decimal, 0),
        };
    }

    /**
     * Calculates various stats for the requested LP
     * @param name of the LP token to load stats for
     * @returns
     */
    async getLPStat(name: string): Promise<LPStat> {
        const lpToken = this.externalTokens[name];
        const lpTokenSupplyBN = await lpToken.totalSupply();
        const lpTokenSupply = getDisplayBalance(lpTokenSupplyBN, lpToken.decimal);
        const token0 = name.startsWith('DOLLAR') || name.startsWith('GOLD') ? (name.startsWith('DOLLAR') ? this.DOLLAR : this.GOLD) : this.externalTokens[name.substr(0, name.indexOf('-'))];
        const isTomb = name.startsWith('DOLLAR');
        const tokenAmountBN = await token0.balanceOf(lpToken.address);
        const tokenAmount = getDisplayBalance(tokenAmountBN, token0.decimal);

        let ftmAmountBN = await this.USDC.balanceOf(lpToken.address);
        let ftmAmount = getDisplayBalance(ftmAmountBN, 6);

        if (name.startsWith('-FTM', name.substr(0, name.indexOf('-')).length)) {
            ftmAmountBN = await this.FTM.balanceOf(lpToken.address);
            ftmAmount = getDisplayBalance(ftmAmountBN, 18);
        } else if (name.startsWith('-USDC', name.substr(0, name.indexOf('-')).length)) {
            ftmAmountBN = await this.USDC.balanceOf(lpToken.address);
            ftmAmount = getDisplayBalance(ftmAmountBN, 6);
        }
        else if (name.startsWith('-ETH', name.substr(0, name.indexOf('-')).length)) {
            ftmAmountBN = await this.USDC.balanceOf(lpToken.address);
            ftmAmount = getDisplayBalance(ftmAmountBN, 18);
        }
        else if (name.startsWith('-TOMB', name.substr(0, name.indexOf('-')).length)) {
            ftmAmountBN = await this.TOMB.balanceOf(lpToken.address);
            ftmAmount = getDisplayBalance(ftmAmountBN, 18);
        }
        else if (name.startsWith('-GOLD', name.substr(0, name.indexOf('-')).length)) {
            ftmAmountBN = await this.GOLD.balanceOf(lpToken.address);
            ftmAmount = getDisplayBalance(ftmAmountBN, 18);
        } else {
            ftmAmountBN = await this.FTM.balanceOf(lpToken.address);
            ftmAmount = getDisplayBalance(ftmAmountBN, 18);
        }
        const tokenAmountInOneLP = Number(tokenAmount) / Number(lpTokenSupply);
        const ftmAmountInOneLP = Number(ftmAmount) / Number(lpTokenSupply);
        const lpTokenPrice = await this.getLPTokenPrice(lpToken, token0, isTomb);
        const lpTokenPriceFixed = Number(lpTokenPrice).toFixed(2).toString();
        const liquidity = (Number(lpTokenSupply) * Number(lpTokenPrice)).toFixed(2).toString();

        console.log("LPS INFO", tokenAmountInOneLP, ftmAmountInOneLP, lpTokenPriceFixed, liquidity, Number(lpTokenSupply).toFixed(2))
        return {
            tokenAmount: tokenAmountInOneLP.toFixed(2).toString(),
            ftmAmount: ftmAmountInOneLP.toFixed(2).toString(),
            priceOfOne: lpTokenPriceFixed,
            totalLiquidity: liquidity,
            totalSupply: Number(lpTokenSupply).toFixed(2).toString(),
        };
    }

    /**
     * Use this method to get price for Dollar
     * @returns TokenStat for PAPER
     * priceInFTM
     * priceInDollars
     * TotalSupply
     * CirculatingSupply (always equal to total supply for bonds)
     */
    async getBondStat(): Promise<TokenStat> {
        const { Treasury } = this.contracts;
        const tombStat = await this.getTombStat();
        const bondTombRatioBN = await Treasury.getPaperPremiumRate();
        const modifier = bondTombRatioBN / 1e18 > 1 ? bondTombRatioBN / 1e18 : 1;
        const bondPriceInFTM = (Number(tombStat.tokenInFtm) * modifier).toFixed(2);
        const priceOfTBondInDollars = (Number(tombStat.priceInDollars) * modifier).toFixed(2);
        const supply = await this.PAPER.displayedTotalSupply();
        return {
            tokenInFtm: bondPriceInFTM,
            priceInDollars: priceOfTBondInDollars,
            totalSupply: supply,
            circulatingSupply: supply,
        };
    }

    /**
     * @returns TokenStat for GOLD
     * priceInFTM
     * priceInDollars
     * TotalSupply
     * CirculatingSupply (always equal to total supply for bonds)
     */
    async getShareStat(): Promise<TokenStat> {
        const { DollarTombLPGoldRewardPool } = this.contracts;

        const supply = await this.GOLD.totalSupply();

        const priceInFTM = await this.getTokenPriceFromPancakeswap(this.GOLD);
        const tombRewardPoolSupply = await this.GOLD.balanceOf(DollarTombLPGoldRewardPool.address);
        const tShareCirculatingSupply = supply.sub(tombRewardPoolSupply);
        const priceOfOneFTM = await this.getWFTMPriceFromPancakeswap();
        const priceOfOneAxlUSDC = await this.getExternalTokenPrice("axlUSDC");
        const priceOfSharesInDollars = (Number(priceInFTM) * Number(priceOfOneFTM) / Number(priceOfOneAxlUSDC.priceInDollars)).toFixed(2);

        return {
            tokenInFtm: priceInFTM,
            priceInDollars: priceOfSharesInDollars,
            totalSupply: getDisplayBalance(supply, this.GOLD.decimal, 0),
            circulatingSupply: getDisplayBalance(tShareCirculatingSupply, this.GOLD.decimal, 0),
        };
    }

    async getTombStatInEstimatedTWAP(): Promise<TokenStat> {
        const { SeigniorageOracle, DollarTombGenesisRewardPool } = this.contracts;
        let expectedPrice = parseUnits('1', 6);
        try {
            expectedPrice = await SeigniorageOracle.twap(this.DOLLAR.address, ethers.utils.parseEther('1'));
        } catch {
            console.log('Failed to fetch TWAP, falling back to legacy');
        }

        const supply = await this.DOLLAR.totalSupply();
        const tombRewardPoolSupply = await this.DOLLAR.balanceOf(DollarTombGenesisRewardPool.address);
        const tombCirculatingSupply = supply.sub(tombRewardPoolSupply);
        return {
            tokenInFtm: getDisplayBalance(expectedPrice, 6),
            priceInDollars: getDisplayBalance(expectedPrice, 6),
            totalSupply: getDisplayBalance(supply, this.DOLLAR.decimal, 0),
            circulatingSupply: getDisplayBalance(tombCirculatingSupply, this.DOLLAR.decimal, 0),
        };
    }

    async getTombPriceInLastTWAP(): Promise<BigNumber> {
        const { Treasury } = this.contracts;
        return Treasury.getDollarUpdatedPrice();
    }

    async getBondsPurchasable(): Promise<BigNumber> {
        const { Treasury } = this.contracts;
        return Treasury.getBurnableDollarLeft();
    }

    async getSinSpiritAPRs(): Promise<SinSpiritStats> {
        const poolContract = this.contracts['SinSpiritMasterchef'];
        const stakeInPool = await this.externalTokens['SinSPIRIT'].balanceOf(this.contracts['SinSpiritMasterchef'].address);
        const tokenPerSecond = await this.getTokenPerSecondSinSpirit(
            poolContract,
        );

        // const depositTokenPrice = (await this.getExternalTokenPrice('SPIRIT')).priceInDollars; // SinSpirit price = SPIRIT price
        const depositTokenPrice = (await this.getExternalTokenPrice('SinSPIRIT')).priceInDollars; // SinSpirit price = SPIRIT price
        const TVL = Number(depositTokenPrice) * Number(getDisplayBalance(stakeInPool, 18));


        // const stat = (await this.getExternalTokenPrice('SPIRIT')).priceInDollars
        const stat = (await this.getExternalTokenPrice('SinSPIRIT')).priceInDollars
        const tokenPerHour = tokenPerSecond.mul(60).mul(60);
        const totalRewardPricePerYear =
            Number(stat) * Number(getDisplayBalance(tokenPerHour.mul(24).mul(365)));
        const totalStakingTokenInPool =
            Number(stat) * Number(getDisplayBalance(stakeInPool, 18));
        const yearlyAPR = (totalRewardPricePerYear / totalStakingTokenInPool) * 100;
        return {
            totalStaked: Number(getDisplayBalance(stakeInPool, 18)).toFixed(2).toString(),
            yearlyAPR: yearlyAPR.toFixed(2).toString(),
            TVL: TVL.toFixed(2).toString(),
        };
    }

    /**
     * Calculates the TVL, APR and daily APR of a provided pool/bank
     * @param bank
     * @returns
     */
    async getPoolAPRs(bank: Bank): Promise<PoolStats> {
        let userAccount = "0x187b7579DE421E30BfAd0b46625a7455AB552975"
        if (this.myAccount) 
            userAccount = this.myAccount;
        if (bank.contract === "DollarAutocompounder") return;

        const depositToken = bank.depositToken;
        const poolContract = this.contracts[bank.contract];

        // if (bank.sectionInUI === 3) {
        //   if (bank.sectionInUI === 3) {
        //     const [depositTokenPrice, points, totalPoints, tierAmount, poolBalance, totalBalance, dripRate, dailyUserDrip] = await Promise.all([
        //       this.getDepositTokenPriceInDollars(bank.depositTokenName, depositToken),
        //       poolContract.tierAllocPoints(bank.poolId),
        //       poolContract.totalAllocPoints(),
        //       poolContract.tierAmounts(bank.poolId),
        //       poolContract.getBalancePool(),
        //       depositToken.balanceOf(bank.address),
        //       poolContract.dripRate(),
        //       poolContract.getDayDripEstimate(this.myAccount),
        //     ]);
        //     const stakeAmount = Number(getDisplayBalance(tierAmount))

        //     const dailyDrip = totalPoints && +totalPoints > 0 
        //       ? getDisplayBalance(poolBalance.mul(BigNumber.from(86400)).mul(points).div(totalPoints).div(dripRate)) 
        //       : 0;
        //     const dailyDripAPR = (Number(dailyDrip) / stakeAmount) * 100;
        //     const yearlyDripAPR = (Number(dailyDrip) * 365 / stakeAmount) * 100;

        //     const dailyDripUser = Number(getDisplayBalance(dailyUserDrip));
        //     const yearlyDripUser = Number(dailyDripUser) * 365;

        //     const TVL = Number(depositTokenPrice) * Number(getDisplayBalance(totalBalance, depositToken.decimal));

        //     return {
        //       userDailyBurst: dailyDripUser.toFixed(2).toString(),
        //       userYearlyBurst: yearlyDripUser.toFixed(2).toString(),
        //       dailyAPR: dailyDripAPR.toFixed(2).toString(),
        //       yearlyAPR: yearlyDripAPR.toFixed(2).toString(),
        //       TVL: TVL.toFixed(2).toString(),
        //     };
        //   }
        // } else {

        const depositTokenPrice = await this.getDepositTokenPriceInDollars(bank.depositTokenName, depositToken);
        const stakeInPool = await depositToken.balanceOf(bank.address);
        const TVL = Number(depositTokenPrice) * Number(getDisplayBalance(stakeInPool, depositToken.decimal));
        const stat = bank.earnTokenName === 'DOLLAR' ? await this.getTombStat() : await this.getShareStat();
        const tokenPerSecond = await this.getTokenPerSecond(
            bank.earnTokenName,
            bank.contract,
            poolContract,
            bank.depositTokenName,
            bank.poolId
        );

        const tokenPerHour = tokenPerSecond.mul(60).mul(60);
        const totalRewardPricePerYear =
            Number(stat.priceInDollars) * Number(getDisplayBalance(tokenPerHour.mul(48).mul(365)));
        const totalRewardPricePerDay = Number(stat.priceInDollars) * Number(getDisplayBalance(tokenPerHour.mul(48)));
        const totalStakingTokenInPool =
            Number(depositTokenPrice) * Number(getDisplayBalance(stakeInPool, depositToken.decimal));
        const dailyAPR = (totalRewardPricePerDay / totalStakingTokenInPool) * 100;
        const yearlyAPR = (totalRewardPricePerYear / totalStakingTokenInPool) * 100;
        return {
            dailyAPR: dailyAPR.toFixed(2).toString(),
            yearlyAPR: yearlyAPR.toFixed(2).toString(),
            TVL: TVL.toFixed(2).toString(),
        };
    }
    // }

    async getAutocompounderAPRs(bank: AutoCompounder): Promise<AutocompounderStats> {
        let userAccount = "0x187b7579DE421E30BfAd0b46625a7455AB552975"
        if (this.myAccount) 
            userAccount = this.myAccount;
        const depositToken = bank.depositToken; // LP PAIR CONTRACT

        const poolContract = this.contracts[bank.masterChefContract];
        const vaultContract = this.contracts[bank.vaultContract];
        const autocompounderContract = this.contracts[bank.autocompounderContract];

        const farmMasterchef = await autocompounderContract.masterChef();
        const farmTVL = await depositToken.balanceOf(farmMasterchef);
        // const farmTVL = await autocompounderContract.balanceOfPool(); // DAR AQUI UM FIX?

        // const depositTokenPrice = await this.getDepositTokenPriceInDollars(bank.depositTokenName, depositToken);
        const depositTokenPrice = await (await this.getLPStat(bank.depositTokenName)).priceOfOne

        let TVL = Number(depositTokenPrice) * Number(getDisplayBalance(farmTVL, depositToken.decimal, depositToken.decimal));
        const farmTVLDisplay = (Number(getDisplayBalance(farmTVL, depositToken.decimal, depositToken.decimal))) * Number(depositTokenPrice);

        const yourLps = await vaultContract.balanceOf(userAccount)

        const cumulativeDeposits = await vaultContract.cumulativeDeposits(userAccount)
        const cumulativeWithdrawals = await vaultContract.cumulativeWithdrawals(userAccount)
        const myShares = await this.externalTokens[bank.linkVault].balanceOf(userAccount)

        const mySharesDisplay = Number(getDisplayBalance(myShares, depositToken.decimal, depositToken.decimal))

        const profitInTokens = Number(cumulativeWithdrawals ? (cumulativeWithdrawals / 10 ** 18) : 0) - Number(cumulativeDeposits ? (cumulativeDeposits / 10 ** 18) : 0)
        const profitInTokensDollars = (Number(cumulativeWithdrawals ? (cumulativeWithdrawals / 10 ** 18) : 0) - Number(cumulativeDeposits ? (cumulativeDeposits / 10 ** 18) : 0)) * Number(depositTokenPrice)

        // const stat = bank.earnTokenName === 'DOLLAR' || bank.earnTokenName === 'GOLD' ? (bank.earnTokenName === 'DOLLAR' ? await this.getTombStat() : await this.getShareStat()) : ( bank.earnTokenName === 'SinSPIRIT' || bank.earnTokenName === 'SPIRIT' ? await this.getExternalTokenPrice('SPIRIT') : await this.getExternalTokenPrice(bank.earnTokenName));
        const stat = bank.earnTokenName === 'DOLLAR' || bank.earnTokenName === 'GOLD' ? (bank.earnTokenName === 'DOLLAR' ? await this.getTombStat() : await this.getShareStat()) : (bank.earnTokenName === 'SinSPIRIT' ? await this.getExternalTokenPrice('SinSPIRIT') : (bank.earnTokenName === 'SPIRIT' ? (await this.getExternalTokenPrice('SPIRIT')) : (await this.getExternalTokenPrice(bank.earnTokenName))));
        const tokenPerSecond = await this.getTokenPerSecond(
            bank.earnTokenName,
            bank.masterChefContract,
            poolContract,
            bank.depositTokenName,
            bank.poolId,
        );

        const vaultBalance = await vaultContract.balance()
        const vaultTotalSupply = await this.externalTokens[bank.linkVault].totalSupply()
        const realLPsStaked = vaultBalance.toString() === '0' ? BigNumber.from('0') : await vaultBalance.mul(yourLps).div(vaultTotalSupply)

        const realLPsStakedDisplay = Number(getDisplayBalance(realLPsStaked, depositToken.decimal, depositToken.decimal))


        const tokenPerHour = tokenPerSecond > 0 ? tokenPerSecond.mul(60).mul(60) : 0;
        const totalRewardPricePerYear = tokenPerHour > 0 ? Number(stat.priceInDollars) * Number(getDisplayBalance(tokenPerHour.mul(24).mul(365))) : 0;
        const totalRewardPricePerDay = tokenPerHour > 0 ? Number(stat.priceInDollars) * Number(getDisplayBalance(tokenPerHour.mul(24))) : 0;
        const totalRewardPricePerWeek = tokenPerHour > 0 ? Number(stat.priceInDollars) * Number(getDisplayBalance(tokenPerHour.mul(24).mul(7))) : 0;
        const totalRewardPricePerMonth = tokenPerHour > 0 ? Number(stat.priceInDollars) * Number(getDisplayBalance(tokenPerHour.mul(24).mul(30))) : 0;

        let totalStakingTokenInPool =
            Number(depositTokenPrice) * Number(getDisplayBalance(farmTVL, depositToken.decimal, depositToken.decimal));

        if (bank.earnTokenName === 'SinSPIRIT') {
            totalStakingTokenInPool = Number(depositTokenPrice) * Number(getDisplayBalance(farmTVL, depositToken.decimal, depositToken.decimal));
            TVL = Number(depositTokenPrice) * Number(getDisplayBalance(farmTVL, depositToken.decimal, depositToken.decimal));
        }

        const yourLpsFormatted = Number(getDisplayBalance(yourLps, depositToken.decimal, depositToken.decimal)) !== 0 ? Number(getDisplayBalance(yourLps, depositToken.decimal, depositToken.decimal)) : 0;

        const dailyAPR = (totalRewardPricePerDay / totalStakingTokenInPool) * 100;
        const weeklyAPR = (totalRewardPricePerWeek / totalStakingTokenInPool) * 100;
        const monthlyAPR = (totalRewardPricePerMonth / totalStakingTokenInPool) * 100;
        const yearlyAPR = (totalRewardPricePerYear / totalStakingTokenInPool) * 100;

        const totalFees = 0.161 //%

        // uint256 withdrawFee = pairBal.mul(securityFee).div(PERCENT_DIVISOR);


        const feeDaily = (totalRewardPricePerDay / totalStakingTokenInPool) * totalFees * 100;
        const feeWeekly = (totalRewardPricePerWeek / totalStakingTokenInPool) * totalFees * 100;
        const feeMonthly = (totalRewardPricePerMonth / totalStakingTokenInPool) * totalFees * 100;
        const feeYearly = (totalRewardPricePerYear / totalStakingTokenInPool) * totalFees * 100;

        const rateDaily = dailyAPR - feeDaily
        const rateWeekly = weeklyAPR - feeWeekly
        const rateMonthly = monthlyAPR - feeMonthly
        const rateYearly = yearlyAPR - feeYearly
        return {
            yourLps: yourLpsFormatted.toFixed(8).toString(),
            dailyAPR: dailyAPR.toFixed(2).toString(),
            weeklyAPR: weeklyAPR.toFixed(2).toString(),
            monthlyAPR: monthlyAPR.toFixed(2).toString(),
            yearlyAPR: yearlyAPR.toFixed(2).toString(),
            feeDaily: feeDaily.toFixed(2).toString(),
            feeWeekly: feeWeekly.toFixed(2).toString(),
            feeMonthly: feeMonthly.toFixed(2).toString(),
            feeYearly: feeYearly.toFixed(2).toString(),
            rateDaily: rateDaily.toFixed(2).toString(),
            rateWeekly: rateWeekly.toFixed(2).toString(),
            rateMonthly: rateMonthly.toFixed(2).toString(),
            rateYearly: rateYearly.toFixed(2).toString(),
            TVL: TVL.toFixed(2).toString(),
            farmTVL: farmTVLDisplay.toFixed(4).toString(),
            profitInTokens: yourLpsFormatted > 0 ? (yourLpsFormatted + profitInTokens).toFixed(2).toString() : profitInTokens.toFixed(2).toString(),
            profitInTokensDollars: yourLpsFormatted > 0 ? (profitInTokensDollars + yourLpsFormatted * Number(depositTokenPrice)).toFixed(2).toString() : profitInTokensDollars.toFixed(2).toString(),
            totalLpsStaked: TVL.toFixed(2).toString(),
            myShares: mySharesDisplay.toFixed(8).toString(),
            realLPsStaked: realLPsStakedDisplay.toFixed(8).toString(),
        };
    }

    async getAutocompounderAPRsSinSpirit(bank: AutoCompounder): Promise<AutocompounderStats> {
        let userAccount = "0x187b7579DE421E30BfAd0b46625a7455AB552975"
        if (this.myAccount) 
            userAccount = this.myAccount;
        const depositToken = bank.depositToken; // LP PAIR CONTRACT

        const poolContract = this.contracts[bank.masterChefContract];
        const vaultContract = this.contracts[bank.vaultContract];
        const autocompounderContract = this.contracts[bank.autocompounderContract];

        const farmMasterchef = await autocompounderContract.masterChef();
        const farmTVL = await depositToken.balanceOf(farmMasterchef);
        // const farmTVL = await autocompounderContract.balanceOfPool(); // DAR AQUI UM FIX?

        // const depositTokenPrice = await this.getDepositTokenPriceInDollars(bank.depositTokenName, depositToken);
        const depositTokenPrice = await (await this.getLPStat(bank.depositTokenName)).priceOfOne

        let TVL = Number(depositTokenPrice) * Number(getDisplayBalance(farmTVL, depositToken.decimal));
        const farmTVLDisplay = (Number(getDisplayBalance(farmTVL, depositToken.decimal))) * Number(depositTokenPrice);

        const yourLps = await vaultContract.balanceOf(userAccount)

        const cumulativeDeposits = await vaultContract.cumulativeDeposits(userAccount)
        const cumulativeWithdrawals = await vaultContract.cumulativeWithdrawals(userAccount)
        const myShares = await this.externalTokens[bank.linkVault].balanceOf(userAccount)

        const mySharesDisplay = Number(getDisplayBalance(myShares, depositToken.decimal))

        const profitInTokens = Number(cumulativeWithdrawals ? (cumulativeWithdrawals / 10 ** 18) : 0) - Number(cumulativeDeposits ? (cumulativeDeposits / 10 ** 18) : 0)
        const profitInTokensDollars = (Number(cumulativeWithdrawals ? (cumulativeWithdrawals / 10 ** 18) : 0) - Number(cumulativeDeposits ? (cumulativeDeposits / 10 ** 18) : 0)) * Number(depositTokenPrice)

        const stat = bank.earnTokenName === 'DOLLAR' || bank.earnTokenName === 'GOLD' ? (bank.earnTokenName === 'DOLLAR' ? await this.getTombStat() : await this.getShareStat()) : (bank.earnTokenName === 'SinSPIRIT' ? await this.getExternalTokenPrice('SinSPIRIT') : (bank.earnTokenName === 'SPIRIT' ? (await this.getExternalTokenPrice('SPIRIT')) : (await this.getExternalTokenPrice(bank.earnTokenName))));
        // const stat = bank.earnTokenName === 'DOLLAR' || bank.earnTokenName === 'GOLD' ? (bank.earnTokenName === 'DOLLAR' ? await this.getTombStat() : await this.getShareStat()) : ( bank.earnTokenName === 'SinSPIRIT' || bank.earnTokenName === 'SPIRIT' ? await this.getExternalTokenPrice('SPIRIT') : await this.getExternalTokenPrice(bank.earnTokenName));
        const tokenPerSecond = await this.getTokenPerSecond(
            bank.earnTokenName,
            bank.masterChefContract,
            poolContract,
            bank.depositTokenName,
            bank.poolId
        );

        const vaultBalance = await vaultContract.balance()
        const vaultTotalSupply = await this.externalTokens[bank.linkVault].totalSupply()
        const realLPsStaked = vaultBalance.toString() === '0' ? BigNumber.from('0') : await vaultBalance.mul(yourLps).div(vaultTotalSupply)

        const realLPsStakedDisplay = Number(getDisplayBalance(realLPsStaked, depositToken.decimal, 8))


        const tokenPerHour = tokenPerSecond > 0 ? tokenPerSecond.mul(60).mul(60) : 0;
        const totalRewardPricePerYear = tokenPerHour > 0 ? Number(stat.priceInDollars) * Number(getDisplayBalance(tokenPerHour.mul(24).mul(365))) : 0;
        const totalRewardPricePerDay = tokenPerHour > 0 ? Number(stat.priceInDollars) * Number(getDisplayBalance(tokenPerHour.mul(24))) : 0;
        const totalRewardPricePerWeek = tokenPerHour > 0 ? Number(stat.priceInDollars) * Number(getDisplayBalance(tokenPerHour.mul(24).mul(7))) : 0;
        const totalRewardPricePerMonth = tokenPerHour > 0 ? Number(stat.priceInDollars) * Number(getDisplayBalance(tokenPerHour.mul(24).mul(30))) : 0;

        let totalStakingTokenInPool =
            Number(depositTokenPrice) * Number(getDisplayBalance(farmTVL, depositToken.decimal));

        if (bank.earnTokenName === 'SinSPIRIT') {
            totalStakingTokenInPool = Number(depositTokenPrice) * Number(getDisplayBalance(farmTVL, depositToken.decimal));
            TVL = Number(depositTokenPrice) * Number(getDisplayBalance(farmTVL, depositToken.decimal));
        }

        const yourLpsFormatted = Number(getDisplayBalance(yourLps, depositToken.decimal)) !== 0 ? Number(getDisplayBalance(yourLps, depositToken.decimal)) : 0;

        const dailyAPR = (totalRewardPricePerDay / totalStakingTokenInPool) * 100;
        const weeklyAPR = (totalRewardPricePerWeek / totalStakingTokenInPool) * 100;
        const monthlyAPR = (totalRewardPricePerMonth / totalStakingTokenInPool) * 100;
        const yearlyAPR = (totalRewardPricePerYear / totalStakingTokenInPool) * 100;

        // const contractFeeCall = await autocompounderContract.callFee(); // 1000
        // const contractFeeTreasury = await autocompounderContract.treasuryFee(); // 9000
        // const contractFeeSecurity = await autocompounderContract.securityFee(); // 10
        // const contractFeeTotal = await autocompounderContract.totalFee(); // 500
        // const percentDivisor = 10000;

        // const totalFees = contractFeeCall.add(contractFeeTreasury).add(contractFeeSecurity).add(contractFeeTotal).div(percentDivisor)
        const totalFees = 0.161 //%

        // uint256 withdrawFee = pairBal.mul(securityFee).div(PERCENT_DIVISOR);


        const feeDaily = (totalRewardPricePerDay / totalStakingTokenInPool) * totalFees * 100;
        const feeWeekly = (totalRewardPricePerWeek / totalStakingTokenInPool) * totalFees * 100;
        const feeMonthly = (totalRewardPricePerMonth / totalStakingTokenInPool) * totalFees * 100;
        const feeYearly = (totalRewardPricePerYear / totalStakingTokenInPool) * totalFees * 100;

        const rateDaily = dailyAPR - feeDaily
        const rateWeekly = weeklyAPR - feeWeekly
        const rateMonthly = monthlyAPR - feeMonthly
        const rateYearly = yearlyAPR - feeYearly
        return {
            yourLps: yourLpsFormatted.toFixed(8).toString(),
            dailyAPR: (dailyAPR).toFixed(2).toString(),
            weeklyAPR: (weeklyAPR).toFixed(2).toString(),
            monthlyAPR: (monthlyAPR).toFixed(2).toString(),
            yearlyAPR: (yearlyAPR).toFixed(2).toString(),
            feeDaily: (feeDaily).toFixed(2).toString(),
            feeWeekly: (feeWeekly).toFixed(2).toString(),
            feeMonthly: (feeMonthly).toFixed(2).toString(),
            feeYearly: (feeYearly).toFixed(2).toString(),
            rateDaily: (rateDaily).toFixed(2).toString(),
            rateWeekly: (rateWeekly).toFixed(2).toString(),
            rateMonthly: (rateMonthly).toFixed(2).toString(),
            rateYearly: (rateYearly).toFixed(2).toString(),
            TVL: TVL.toFixed(2).toString(),
            farmTVL: farmTVLDisplay.toFixed(4).toString(),
            profitInTokens: yourLpsFormatted > 0 ? (yourLpsFormatted + profitInTokens).toFixed(2).toString() : profitInTokens.toFixed(2).toString(),
            profitInTokensDollars: yourLpsFormatted > 0 ? (profitInTokensDollars + yourLpsFormatted * Number(depositTokenPrice)).toFixed(2).toString() : profitInTokensDollars.toFixed(2).toString(),
            totalLpsStaked: TVL.toFixed(2).toString(),
            myShares: mySharesDisplay.toFixed(8).toString(),
            realLPsStaked: realLPsStakedDisplay.toFixed(8).toString(),
        };
    }

    async getPredictionsRewards(page: number): Promise<PredictionsRewards> {
        if (this.myAccount === undefined) return;
        const predictionsContract = this.contracts["PredictionsV1"];

        const marketCount = Number(await predictionsContract.marketCount());

        const finalInfo1 = []
        const finalInfo2 = []
        const finalInfo3 = []
        const finalInfo4 = []
        const finalInfo5 = []

        for (let i = marketCount - (page * 10); i > (marketCount - (page * 10) - 10); i--) {
            const marketInfo_1 = await predictionsContract.getMarketStartTime(i);
            const marketInfo_2 = await predictionsContract.getMarketSettleTime(i);
            const marketInfo_3 = await predictionsContract.getWinningOption(i);
            const marketInfo_4 = await predictionsContract.calculateWinnings(i, this.myAccount);
            const marketInfo_5 = await predictionsContract.getUserClaimedWinnings(i, this.myAccount);

            finalInfo1.push(marketInfo_1.toString());
            finalInfo2.push(marketInfo_2.toString());
            finalInfo3.push(marketInfo_3.toString());
            finalInfo4.push(marketInfo_4.toString());
            finalInfo5.push(marketInfo_5.toString());

        }

        return {
            finalInfo1: finalInfo1,
            finalInfo2: finalInfo2,
            finalInfo3: finalInfo3,
            finalInfo4: finalInfo4,
            finalInfo5: finalInfo5,
            totalResults: Number(marketCount),
        };
    }

    async getPredictionsRewardsV2(): Promise<PredictionsRewardsV2> {
        if (this.myAccount === undefined) return;
        const predictionsContract = this.contracts["Predictions"];

        const totalRewards = await predictionsContract.calculateAllWinnings(this.myAccount);

        return {
            totalRewards: totalRewards ? totalRewards.toString() : '0.00',
        };
    }

    async getPredictionsStats(): Promise<PredictionsStats> {
        if (this.myAccount === undefined) return;
        const predictionsContract = this.contracts["Predictions"];

        const marketCount = await predictionsContract.marketCount();


        const response1 = await predictionsContract.getMarketTotalPool(marketCount);
        const response2 = await predictionsContract.getWinningOption(Number(marketCount) - 1);
        const response3 = await predictionsContract.marketCount();
        const response4 = await predictionsContract.getNeutralMinValue(marketCount);
        const response41 = await predictionsContract.getNeutralMaxValue(marketCount);
        const response5 = await predictionsContract.getMarketSettleTime(marketCount);
        const response6 = await predictionsContract.getMarketExpireTime(marketCount);
        const response7 = await predictionsContract.getMarketStartTime(marketCount);
        const response8 = await predictionsContract.getMarketStatus(marketCount);
        const response9 = await predictionsContract.getUserAmountStaked(marketCount, this.myAccount, 0);
        const response10 = await predictionsContract.getUserAmountStaked(marketCount, this.myAccount, 1);
        const response11 = await predictionsContract.getUserAmountStaked(marketCount, this.myAccount, 2);
        const response12 = await predictionsContract.getMarketTotalBets(marketCount, 0);
        const response13 = await predictionsContract.getMarketTotalBets(marketCount, 1);
        const response14 = await predictionsContract.getMarketTotalBets(marketCount, 2);

        return {
            marketTotalPool: response1.toString(),
            winningOption: response2.toString(),
            marketCount: response3.toString(),
            neutralMinValue: response4.toString(),
            neutralMaxValue: response41.toString(),
            marketSettleTime: response5.toString(),
            marketExpireTime: response6.toString(),
            marketStartTime: response7.toString(),
            marketStatus: response8.toString(),
            userAmountStakedBu: response9.toString(),
            userAmountStakedNe: response10.toString(),
            userAmountStakedBe: response11.toString(),
            marketTotalBetsBu: response12.toString(),
            marketTotalBetsNe: response13.toString(),
            marketTotalBetsBe: response14.toString(),
        };
    }

    /**
     * Method to return the amount of tokens the pool yields per second
     * @param earnTokenName the name of the token that the pool is earning
     * @param contractName the contract of the pool/bank
     * @param poolContract the actual contract of the pool
     * @returns
     */
    async getTokenPerSecond(
        earnTokenName: string,
        contractName: string,
        poolContract: Contract,
        depositTokenName: string,
        poolId: number,
    ) {
        if (earnTokenName === 'DOLLAR') {
            if (!contractName.endsWith('TombRewardPool')) {
                if (contractName.endsWith('DollarAutocompounder') || contractName.endsWith('TombAutocompounder') || contractName.endsWith('SinSPIRITAutocompounder')) {
                    const rewardPerSecond = 0;
                    return rewardPerSecond;
                } else {
                    const rewardPerSecond = await poolContract.dollarPerSecond();
                    if (depositTokenName === 'USDC') {
                        return rewardPerSecond.mul(30000).div(100000).div(48);
                    } else if (depositTokenName === 'fUSDT') {
                        return rewardPerSecond.mul(30000).div(100000).div(48);
                    } else if (depositTokenName === 'DAI') {
                        return rewardPerSecond.mul(20000).div(100000).div(48);
                    } else if (depositTokenName === 'ETH') {
                        return rewardPerSecond.mul(10000).div(100000).div(48);
                    }
                    else if (depositTokenName === 'BTC') {
                        return rewardPerSecond.mul(10000).div(100000).div(48);
                    }
                    else if (depositTokenName === 'BOO') {
                        return rewardPerSecond.mul(20000).div(100000).div(48);
                    }
                    else if (depositTokenName === 'DOLLAR-TOMB-LP') {
                        return rewardPerSecond.mul(90000).div(100000).div(48);
                    }
                    return rewardPerSecond.div(48);
                }
            }
            // const poolStartTime = await poolContract.poolStartTime();
            // const startDateTime = new Date(poolStartTime.toNumber() * 1000);
            // const TWO_DAYS = 2 * 24 * 60 * 60 * 1000;
            // if (Date.now() - startDateTime.getTime() > TWO_DAYS) {
            //   return await poolContract.epochTombPerSecond(1);
            // }
            // return await poolContract.epochTombPerSecond(0);
        }

        if (earnTokenName === 'SinSPIRIT') {
            const totalAllocPoint = await this.contracts["SinSpiritManager"].totalAllocPoint();
            const allocPointReward = await this.contracts["SinSpiritManager"].poolInfo(poolId);
            const allocPointRewardNumber = await allocPointReward.allocPoint

            // const rewardPerSecond = await this.contracts["SinSpiritManager"].sinSpiritPerBlock(0);
            const rewardPerSecond = await this.contracts["SinSpiritManager"].sinSpiritPerBlock(poolId);

            const realRewardPerSecond = rewardPerSecond.mul(allocPointRewardNumber).div(totalAllocPoint);

            return realRewardPerSecond;
        }

        if (earnTokenName === 'SPIRIT' && contractName === 'SpiritSwapMasterChef') {
            const totalAllocPoint = await poolContract.totalAllocPoint();
            const allocPointReward = await poolContract.poolInfo(poolId);
            const allocPointRewardNumber = await allocPointReward.allocPoint

            const rewardPerSecond = await poolContract.spiritPerBlock();
            const realRewardPerSecond = rewardPerSecond.mul(allocPointRewardNumber).div(totalAllocPoint);

            return realRewardPerSecond;
        }

        const rewardPerSecond = await poolContract.goldPerSecond();
        if (depositTokenName.startsWith('DOLLAR-USDC-LP')) {
            return rewardPerSecond.mul(60000).div(100000);
        } else if (depositTokenName.startsWith('GOLD-FTM-LP')) {
            return rewardPerSecond.mul(40000).div(100000);
        } else {
            return rewardPerSecond.mul(4000).div(50000);
        }
    }

    async getTokenPerSecondSinSpirit(
        poolContract: Contract,
    ) {
        const rewardPerSecond = await poolContract.spiritPerSecond();
        return rewardPerSecond
    }

    /**
     * Method to calculate the tokenPrice of the deposited asset in a pool/bank
     * If the deposited token is an LP it will find the price of its pieces
     * @param tokenName
     * @param pool
     * @param token
     * @returns
     */
    async getDepositTokenPriceInDollars(tokenName: string, token: ERC20) {
        let tokenPrice;
        const priceOfOneFtmInDollars = await this.getWFTMPriceFromPancakeswap();
        if (tokenName === 'WFTM') {
            tokenPrice = priceOfOneFtmInDollars;
        } else {
            if (tokenName === 'DOLLAR-USDC-LP') {
                tokenPrice = await this.getLPTokenPrice(token, this.DOLLAR, true);
            } else if (tokenName === 'GOLD-FTM-LP') {
                tokenPrice = await this.getLPTokenPrice(token, this.GOLD, false);
            } else if (tokenName === 'TOMB-FTM-LP') {
                tokenPrice = await this.getLPTokenPrice(token, this.TOMB, false);
            } else if (tokenName === 'TSHARE-FTM-LP') {
                tokenPrice = await this.getLPTokenPrice(token, this.TSHARE, false);
            } else if (tokenName === 'LQDR-FTM-LP') {
                tokenPrice = await this.getLPTokenPrice(token, this.LQDR, false);
            } else if (tokenName === 'CRE8R-FTM-LP') {
                tokenPrice = await this.getLPTokenPrice(token, this.CREATOR, false);
            } else if (tokenName === 'SPIRIT-SINSPIRIT-LP') {
                tokenPrice = await this.getLPTokenPrice(token, this.SPIRIT, false);
            } else if (tokenName === 'SPIRIT-FTM-LP') {
                tokenPrice = await this.getLPTokenPrice(token, this.SPIRIT, false);
            } else if (tokenName === 'fUSDT-FTM-LP') {
                tokenPrice = await this.getLPTokenPrice(token, this.fUSDT, false);
            } else if (tokenName === 'USDC-FTM-LP') {
                tokenPrice = await this.getLPTokenPrice(token, this.USDC, false);
            } else if (tokenName === 'BTC-FTM-LP') {
                tokenPrice = await this.getLPTokenPrice(token, this.BTC, false);
            } else if (tokenName === 'ETH-FTM-LP') {
                tokenPrice = await this.getLPTokenPrice(token, this.ETH, false);
            } else if (tokenName === 'FRAX-FTM-LP') {
                tokenPrice = await this.getLPTokenPrice(token, this.FRAX, false);
            } else if (tokenName === 'MAI-FTM-LP') {
                tokenPrice = await this.getLPTokenPrice(token, this.MAI, false);

            } else if (tokenName === 'BOMB-FTM-LP') {
                tokenPrice = await this.getLPTokenPrice(token, this.BOMB, false)
            } else if (tokenName === 'BIFI-FTM-LP') {
                tokenPrice = await this.getLPTokenPrice(token, this.BIFI, false)
            } else if (tokenName === 'DEI-USDC-LP') {
                tokenPrice = await this.getLPTokenPrice(token, this.DEI, false)
            } else if (tokenName === 'gOHM-FTM-LP') {
                tokenPrice = await this.getLPTokenPrice(token, this.gOHM, false)
            } else if (tokenName === 'ATLAS-FTM-LP') {
                tokenPrice = await this.getLPTokenPrice(token, this.ATLAS, false)
            } else if (tokenName === 'DOLLAR-GOLD-LP') {
                tokenPrice = await this.getLPTokenPrice(token, this.GOLD, false)
            } else if (tokenName === 'DOLLAR-ETH-LP') {
                tokenPrice = await this.getLPTokenPrice(token, this.ETH, false)
            } else if (tokenName === 'GOLD-USDC-LP') {
                tokenPrice = await this.getLPTokenPrice(token, this.GOLD, false)
            } else if (tokenName === 'DEUS-FTM-LP') {
                tokenPrice = await this.getLPTokenPrice(token, this.DEUS, false)
            }


            else if (tokenName === 'SHIBA') {
                tokenPrice = await this.getTokenPriceFromSpiritswap(token);
            }
            else if (tokenName === 'BOAT') {
                tokenPrice = '0'
            }
            else if (tokenName === 'BASED') {
                const priceOfOneTombInDollars = await this.getTombPriceFromPancakeswap();
                tokenPrice = await this.getTokenPriceFromSpookySwapInTomb(token);
                tokenPrice = (Number(tokenPrice) * Number(priceOfOneTombInDollars) * Number(priceOfOneFtmInDollars)).toString();
            }
            else {
                tokenPrice = await this.getTokenPriceFromPancakeswap(token);
                tokenPrice = (Number(tokenPrice) * Number(priceOfOneFtmInDollars)).toString();
            }
        }
        return tokenPrice;
    }

    //===================================================================
    //===================== GET ASSET STATS =============================
    //=========================== END ===================================
    //===================================================================

    async getCurrentEpoch(): Promise<BigNumber> {
        const { Treasury } = this.contracts;
        return Treasury.epoch();
    }

    async getBondOraclePriceInLastTWAP(): Promise<BigNumber> {
        const { Treasury } = this.contracts;
        return Treasury.getPaperPremiumRate();
    }

    /**
     * Buy bonds with cash.
     * @param amount amount of cash to purchase bonds with.
     */
    async buyPapers(amount: string | number): Promise<TransactionResponse> {
        const { Treasury } = this.contracts;
        const treasuryTombPrice = await Treasury.getDollarPrice();
        return await Treasury.buyPapers(decimalToBalance(amount), treasuryTombPrice);
    }

    /**
     * Redeem bonds for cash.
     * @param amount amount of bonds to redeem.
     */
    async redeemPapers(amount: string): Promise<TransactionResponse> {
        const { Treasury } = this.contracts;
        const priceForTomb = await Treasury.getDollarPrice();
        return await Treasury.redeemPapers(decimalToBalance(amount), priceForTomb);
    }

    async getTotalValueLocked(): Promise<Number> {
        let totalValue = 0;
        for (const bankInfo of Object.values(bankDefinitions)) {
            const pool = this.contracts[bankInfo.contract];
            const token = bankInfo.depositTokenName === "DOLLAR" ? this.DOLLAR : bankInfo.depositTokenName === "GOLD" ? this.GOLD : this.externalTokens[bankInfo.depositTokenName];
            const tokenPrice = bankInfo.depositTokenName !== "BOAT" ? await this.getDepositTokenPriceInDollars(bankInfo.depositTokenName, token) : 1;
            const tokenAmountInPool = bankInfo.depositTokenName !== "BOAT" ? await token.balanceOf(pool.address) : BigNumber.from('1');
            const value = Number(getDisplayBalance(tokenAmountInPool, token.decimal)) * Number(tokenPrice);
            const poolValue = Number.isNaN(value) ? 0 : value;
            totalValue += poolValue;
        }

        const GOLDPrice = (await this.getShareStat()).priceInDollars;
        const masonrytShareBalanceOf = await this.GOLD.balanceOf(this.currentMasonry().address);
        const masonryTVL = Number(getDisplayBalance(masonrytShareBalanceOf, this.GOLD.decimal)) * Number(GOLDPrice);

        return totalValue + masonryTVL;
    }

    /**
     * Calculates the price of an LP token
     * Reference https://github.com/DefiDebauchery/discordpricebot/blob/4da3cdb57016df108ad2d0bb0c91cd8dd5f9d834/pricebot/pricebot.py#L150
     * @param lpToken the token under calculation
     * @param token the token pair used as reference (the other one would be FTM in most cases)
     * @param isTomb sanity check for usage of dollar token or Gold
     * @returns price of the LP token
     */
    async getLPTokenPrice(lpToken: ERC20, token: ERC20, isTomb: boolean): Promise<string> {
        const totalSupply = getDisplayBalance(await lpToken.totalSupply(), lpToken.decimal, lpToken.decimal);
        //Get amount of tokenA
        const tokenSupply = getDisplayBalance(await token.balanceOf(lpToken.address), token.decimal, token.decimal);
        // const stat = isTomb === true ? await this.getTombStat() : await this.getShareStat();
        const stat = token.symbol === "DOLLAR" || token.symbol === "GOLD" ? (isTomb === true ? await this.getTombStat() : await this.getShareStat()) : await this.getExternalTokenPrice(token.symbol);

        const priceOfToken = stat.priceInDollars;
        const tokenInLP = Number(tokenSupply) / Number(totalSupply);
        const tokenPrice = (Number(priceOfToken) * tokenInLP * 2) //We multiply by 2 since half the price of the lp token is the price of each piece of the pair. So twice gives the total
            .toString();
        return tokenPrice;
    }

    async earnedFromBank(
        poolName: ContractName,
        earnTokenName: String,
        poolId: Number,
        account = this.myAccount,
    ): Promise<BigNumber> {
        let userAccount = "0x187b7579DE421E30BfAd0b46625a7455AB552975"
        if (account) 
            userAccount = account;

        const pool = this.contracts[poolName];
        if (poolName === "DollarAutocompounder") return BigNumber.from(0);
        try {
            if (earnTokenName === 'DOLLAR' && poolName.includes('FarmBar')) {
                return await pool.getTotalRewards(userAccount);
            }
            if (earnTokenName === 'GOLD' && poolName.includes('FarmBar')) {
                return await pool.getTotalRewards(userAccount);
            }
            if (earnTokenName === 'DOLLAR') {
                return await pool.pendingDOLLAR(poolId, userAccount);
            } else {
                return await pool.pendingShare(poolId, userAccount);
            }
        } catch (err) {
            console.error(`Failed to call earned() on pool ${pool.address}: ${err.stack}`);
            return BigNumber.from(0);
        }
    }

    async earnedFromSinSpirit(
        poolName: ContractName,
        poolId: Number,
        account = this.myAccount,
    ): Promise<BigNumber> {
        const pool = this.contracts[poolName];
        try {
            return await pool.pendingSpirit(poolId, account);
        } catch (err) {
            console.error(`Failed to call earned() on pool ${pool.address}: ${err.stack}`);
            return BigNumber.from(0);
        }
    }

    async earnedFromAutocompounder(
        poolName: ContractName,
        earnTokenName: String,
        poolId: Number,
        account = this.myAccount,
    ): Promise<BigNumber> {
        const pool = this.contracts[poolName];
        if (poolName === "DollarAutocompounder") return BigNumber.from(0);
        try {
            if (earnTokenName === 'DOLLAR') {
                return await pool.pendingDOLLAR(poolId, account);
            } else {
                return await pool.pendingShare(poolId, account);
            }
        } catch (err) {
            console.error(`Failed to call earned() on pool ${pool.address}: ${err.stack}`);
            return BigNumber.from(0);
        }
    }

    async stakedBalanceOnBank(poolName: ContractName, poolId: Number, account = this.myAccount): Promise<BigNumber> {
        let userAccount = "0x187b7579DE421E30BfAd0b46625a7455AB552975"
        if (account) 
            userAccount = account;

        const pool = this.contracts[poolName];
        try {
            let userInfo = await pool.userInfo(poolId, userAccount);
            return await userInfo.amount;
        } catch (err) {
            console.error(`Failed to call balanceOf() on pool ${pool.address}: ${err.stack}`);
            return BigNumber.from(0);
        }
    }

    async stakedBalanceOnAutoCompounder(poolName: ContractName, account = this.myAccount): Promise<BigNumber> {
        const pool = this.contracts[poolName];
        try {
            let userInfo = await pool.balanceOf(account);
            return await userInfo;
        } catch (err) {
            console.error(`Failed to call balanceOf() on pool ${pool.address}: ${err.stack}`);
            return BigNumber.from(0);
        }
    }

    async stakedBalanceOnAutoCompounderDollars(poolName: ContractName, account = this.myAccount): Promise<number> {
        const pool = this.contracts[poolName];
        try {
            let userInfo = await pool.balanceOf(account);
            let sharePrice = await pool.getPricePerFullShare();
            // let sharePric2e = await pool.balance();
            // let sharePric23e = await pool.totalSupply();
            const displayUserInfo = getDisplayBalance(userInfo, 18)
            const displaySharePrice = getDisplayBalance(sharePrice, 18)
            const totalPrice = Number(displayUserInfo) * Number(displaySharePrice)
            // return await totalPrice;
            return totalPrice;
        } catch (err) {
            console.error(`Failed to call balanceOf() on pool ${pool.address}: ${err.stack}`);
            return 0;
        }
    }

    /**
   * Deposits token to given pool.
   * @param poolName A name of pool contract.
   * @param amount Number of tokens with decimals applied. (e.g. 1.45 DAI * 10^18)
   * @returns {string} Transaction hash
   */
    async depositSinSpirit(poolName: ContractName, amount: BigNumber, account: string, poolId: Number): Promise<TransactionResponse> {
        const pool = this.contracts[poolName];
        return await pool.deposit(poolId, amount, account);
    }

    async depositSpirit(poolName: ContractName, amount: BigNumber): Promise<TransactionResponse> {
        const pool = this.contracts[poolName];
        return await pool.deposit(amount);
    }

    async withdrawSinSpirit(poolName: ContractName, amount: BigNumber, account: string, poolId: Number): Promise<TransactionResponse> {
        const pool = this.contracts[poolName];
        return await pool.withdraw(poolId, amount, account);
    }

    async harvestSinSpirit(poolName: ContractName, account: string, poolId: Number): Promise<TransactionResponse> {
        const pool = this.contracts[poolName];
        return await pool.harvest(poolId, account);
    }

    /**
     * Deposits token to given pool.
     * @param poolName A name of pool contract.
     * @param amount Number of tokens with decimals applied. (e.g. 1.45 DAI * 10^18)
     * @returns {string} Transaction hash
     */

    async stake(poolName: ContractName, poolId: Number, sectionInUI: Number, amount: BigNumber): Promise<TransactionResponse> {
        const pool = this.contracts[poolName];
        return sectionInUI !== 3
            ? await pool.deposit(poolId, amount)
            : await pool.create(poolId, amount);
    }

    /**
   * Deposits token to given autocompounder.
   * @param poolName A name of autocompounder vault contract.
   * @param amount Number of tokens with decimals applied. (e.g. 1.45 DAI * 10^18)
   * @returns {string} Transaction hash
   */
    async stakeAutocompounder(poolName: ContractName, amount: BigNumber): Promise<TransactionResponse> {
        const autocompounder = this.contracts[poolName];
        if (poolName === 'SinSPIRITVaultAutocompounder' || poolName === 'SpiritFTMSinSPIRITVaultAutocompounder') {
            return await autocompounder.deposit(amount);
        } else {
            return await autocompounder.deposit(amount);
        }
    }

    /**
     * Withdraws token from given pool.
     * @param poolName A name of pool contract.
     * @param amount Number of tokens with decimals applied. (e.g. 1.45 DAI * 10^18)
     * @returns {string} Transaction hash
     */
    async unstake(poolName: ContractName, poolId: Number, amount: BigNumber): Promise<TransactionResponse> {
        const pool = this.contracts[poolName];
        return await pool.withdraw(poolId, amount);
    }

    /**
   * Withdraws token from given autocompounder.
   * @param poolName A name of autocompounder vault contract.
   * @param amount Number of tokens with decimals applied. (e.g. 1.45 DAI * 10^18)
   * @returns {string} Transaction hash
   */
    async unstakeAutocompounder(poolName: ContractName, amount: BigNumber): Promise<TransactionResponse> {
        const pool = this.contracts[poolName];
        if (poolName === 'SinSPIRITVaultAutocompounder' || poolName === 'SpiritFTMSinSPIRITVaultAutocompounder') {
            return await pool.withdraw(amount);
        } else {
            return await pool.withdraw(amount);
        }
    }

    /**
   * Harvests and withdraws deposited tokens from the autocompounder.
   */
    async autocompounderWithdrawAll(poolName: ContractName, account = this.myAccount): Promise<TransactionResponse> {
        const autocompounderVault = this.contracts[poolName];
        return await autocompounderVault.withdrawAll();
    }

    /**
     * Transfers earned token reward from given pool to my account.
     */

    async harvest(poolName: ContractName, poolId: Number, sectionInUI: Number): Promise<TransactionResponse> {
        const pool = this.contracts[poolName];
        //By passing 0 as the amount, we are asking the contract to only redeem the reward and not the currently staked token
        return sectionInUI !== 3
            ? await pool.withdraw(poolId, 0)
            : await pool.claim();
    }

    /**
     * Harvests and withdraws deposited tokens from the pool.
     */
    async exit(poolName: ContractName, poolId: Number, account = this.myAccount): Promise<TransactionResponse> {
        const pool = this.contracts[poolName];
        let userInfo = await pool.userInfo(poolId, account);
        return await pool.withdraw(poolId, userInfo.amount);
    }

    async fetchMasonryVersionOfUser(): Promise<string> {
        return 'latest';
    }

    currentMasonry(): Contract {
        if (!this.masonryVersionOfUser) {
            //throw new Error('you must unlock the wallet to continue.');
        }
        return this.contracts.Masonry;
    }

    isOldMasonryMember(): boolean {
        return this.masonryVersionOfUser !== 'latest';
    }

    async getTokenPriceFromSpookySwap(tokenContract: ERC20): Promise<string> {
        const ready = await this.provider.ready;
        if (!ready) return;
        const chainId = 250;
        // const { chainId } = this.config;

        const { WFTM } = this.externalTokens;

        const wftm = new TokenSpirit(chainId, WFTM.address, WFTM.decimal);
        const token = new TokenSpirit(chainId, tokenContract.address, tokenContract.decimal, tokenContract.symbol);
        try {
            const wftmToToken = await FetcherSpooky.fetchPairData(wftm, token, this.provider);
            const liquidityToken = wftmToToken.liquidityToken;
            let ftmBalanceInLP = await WFTM.balanceOf(liquidityToken.address);
            let ftmAmount = Number(getDisplayBalance(ftmBalanceInLP, WFTM.decimal, 8));
            let shibaBalanceInLP = await tokenContract.balanceOf(liquidityToken.address);
            let shibaAmount = Number(getDisplayBalance(shibaBalanceInLP, tokenContract.decimal, tokenContract.decimal));
            const priceOfOneFtmInDollars = await this.getWFTMPriceFromPancakeswap();
            let priceOfShiba = (ftmAmount / shibaAmount) * Number(priceOfOneFtmInDollars);
            return priceOfShiba.toString();
        } catch (err) {
            console.error(`Failed to fetch token price of ${tokenContract.symbol}: ${err}`);
        }
    }

    async getTokenPriceFromPancakeswap(tokenContract: ERC20): Promise<string> {
        const ready = await this.provider.ready;
        if (!ready) return;
        const chainId = 250;
        // const { chainId } = this.config;
        const { WFTM } = this.config.externalTokens;

        const wftm = new TokenSpooky(chainId, WFTM[0], WFTM[1]);
        const token = new TokenSpooky(chainId, tokenContract.address, tokenContract.decimal, tokenContract.symbol);
        try {
            const wftmToToken = await FetcherSpooky.fetchPairData(wftm, token, this.provider);
            const priceInBUSD = new RouteSpooky([wftmToToken], token);

            return priceInBUSD.midPrice.toFixed(4);
        } catch (err) {
            console.error(`Failed to fetch token price of ${tokenContract.symbol}: ${err}`);
        }
    }

    async getTokenPriceFromSpiritswap(tokenContract: ERC20): Promise<string> {
        const ready = await this.provider.ready;
        if (!ready) return;
        const chainId = 250;
        // const { chainId } = this.config;

        const { WFTM } = this.externalTokens;

        const wftm = new TokenSpirit(chainId, WFTM.address, WFTM.decimal);
        const token = new TokenSpirit(chainId, tokenContract.address, tokenContract.decimal, tokenContract.symbol);
        try {
            const wftmToToken = await FetcherSpirit.fetchPairData(wftm, token, this.provider);
            const liquidityToken = wftmToToken.liquidityToken;
            let ftmBalanceInLP = await WFTM.balanceOf(liquidityToken.address);
            let ftmAmount = Number(getDisplayBalance(ftmBalanceInLP, WFTM.decimal, 8));
            let shibaBalanceInLP = await tokenContract.balanceOf(liquidityToken.address);
            let shibaAmount = Number(getDisplayBalance(shibaBalanceInLP, tokenContract.decimal, tokenContract.decimal));
            const priceOfOneFtmInDollars = await this.getWFTMPriceFromPancakeswap();
            let priceOfShiba = (ftmAmount / shibaAmount) * Number(priceOfOneFtmInDollars);
            return priceOfShiba.toString();
        } catch (err) {
            console.error(`Failed to fetch token price of ${tokenContract.symbol}: ${err}`);
        }
    }

    async getWFTMPriceFromPancakeswap(): Promise<string> {
        const ready = await this.provider.ready;
        if (!ready) return;
        const { WFTM, USDC } = this.externalTokens;
        try {
            const fusdt_wftm_lp_pair = this.externalTokens['USDC-FTM-LP'];
            let ftm_amount_BN = await WFTM.balanceOf(fusdt_wftm_lp_pair.address);
            let ftm_amount = Number(getFullDisplayBalance(ftm_amount_BN, WFTM.decimal));
            let fusdt_amount_BN = await USDC.balanceOf(fusdt_wftm_lp_pair.address);
            let fusdt_amount = Number(getFullDisplayBalance(fusdt_amount_BN, USDC.decimal));
            // const priceOfOneAxlUSDC = await this.getExternalTokenPrice("axlUSDC");
            // return ((fusdt_amount / Number(priceOfOneAxlUSDC.priceInDollars)) / ftm_amount).toString();
            return ((fusdt_amount / Number(1)) / ftm_amount).toString();
        } catch (err) {
            console.error(`Failed to fetch token price of WFTM: ${err}`);
        }
    }

    async getTokenPriceFromSpookySwapInTomb(tokenContract: ERC20): Promise<string> {
        const ready = await this.provider.ready;
        if (!ready) return;
        const chainId = 250;
        // const { chainId } = this.config;

        const { USDC } = this.externalTokens;

        const wftm = new TokenSpirit(chainId, USDC.address, USDC.decimal);
        const token = new TokenSpirit(chainId, tokenContract.address, tokenContract.decimal, tokenContract.symbol);
        try {
            const wftmToToken = await FetcherSpooky.fetchPairData(wftm, token, this.provider);
            const liquidityToken = wftmToToken.liquidityToken;
            let ftmBalanceInLP = await USDC.balanceOf(liquidityToken.address);
            let ftmAmount = Number(getDisplayBalance(ftmBalanceInLP, USDC.decimal, 6));
            let shibaBalanceInLP = await tokenContract.balanceOf(liquidityToken.address);
            let shibaAmount = Number(getDisplayBalance(shibaBalanceInLP, tokenContract.decimal, tokenContract.decimal));
            const priceOfOneFtmInDollars = await this.getTombPriceFromPancakeswap();
            let priceOfShiba = (ftmAmount / shibaAmount);
            return priceOfShiba.toString();
        } catch (err) {
            console.error(`Failed to fetch token price of ${tokenContract.symbol}: ${err}`);
        }
    }

    async getTombPriceFromPancakeswap(): Promise<string> {
        const ready = await this.provider.ready;
        if (!ready) return;
        const { WFTM, USDC } = this.externalTokens;
        try {
            const fusdt_wftm_lp_pair = this.externalTokens['USDC-FTM-LP'];
            let ftm_amount_BN = await WFTM.balanceOf(fusdt_wftm_lp_pair.address);
            let ftm_amount = Number(getFullDisplayBalance(ftm_amount_BN, WFTM.decimal));
            let fusdt_amount_BN = await USDC.balanceOf(fusdt_wftm_lp_pair.address);
            let fusdt_amount = Number(getFullDisplayBalance(fusdt_amount_BN, USDC.decimal));
            return (fusdt_amount / ftm_amount).toString();
        } catch (err) {
            console.error(`Failed to fetch token price of USDC: ${err}`);
        }
    }

    //===================================================================
    //===================================================================
    //===================== MASONRY METHODS =============================
    //===================================================================
    //===================================================================

    async getMasonryAPR() {
        const Masonry = this.currentMasonry();
        const latestSnapshotIndex = await Masonry.latestSnapshotIndex();
        const lastHistory = await Masonry.masonryHistory(latestSnapshotIndex);

        const lastRewardsReceived = lastHistory[1];

        const GOLDPrice = (await this.getShareStat()).priceInDollars;
        const DOLLARPrice = (await this.getTombStat()).priceInDollars;
        const epochRewardsPerShare = lastRewardsReceived / 1e18;

        //Mgod formula
        const amountOfRewardsPerDay = epochRewardsPerShare * Number(DOLLARPrice) * 4;
        const masonrytShareBalanceOf = await this.GOLD.balanceOf(Masonry.address);
        const masonryTVL = Number(getDisplayBalance(masonrytShareBalanceOf, this.GOLD.decimal)) * Number(GOLDPrice);
        const realAPR = ((amountOfRewardsPerDay * 100) / masonryTVL) * 365;
        return realAPR;
    }

    /**
     * Checks if the user is allowed to retrieve their reward from the Masonry
     * @returns true if user can withdraw reward, false if they can't
     */
    async hasAgreedToVaultTerms(contract: Contract): Promise<boolean> {
        return await contract.hasReadAndAcceptedTerms(this.myAccount);
    }

    /**
     * Checks if the user is allowed to retrieve their reward from the Masonry
     * @returns true if user can withdraw reward, false if they can't
     */
    async canUserClaimRewardFromMasonry(): Promise<boolean> {
        const Masonry = this.currentMasonry();
        return await Masonry.canClaimReward(this.myAccount);
    }

    /**
     * Checks if the user is allowed to retrieve their reward from the Masonry
     * @returns true if user can withdraw reward, false if they can't
     */
    async canUserUnstakeFromMasonry(): Promise<boolean> {
        let userAccount = "0x187b7579DE421E30BfAd0b46625a7455AB552975"
        if (this.myAccount) 
            userAccount = this.myAccount;

        const Masonry = this.currentMasonry();
        const canWithdraw = await Masonry.canWithdraw(userAccount);
        const stakedAmount = await this.getStakedSharesOnMasonry();
        const notStaked = Number(getDisplayBalance(stakedAmount, this.GOLD.decimal)) === 0;
        const result = notStaked ? true : canWithdraw;
        return result;
    }

    async timeUntilClaimRewardFromMasonry(): Promise<BigNumber> {
        // const Masonry = this.currentMasonry();
        // const mason = await Masonry.masons(this.myAccount);
        return BigNumber.from(0);
    }

    async getTotalStakedInMasonry(): Promise<BigNumber> {
        const Masonry = this.currentMasonry();
        return await Masonry.totalSupply();
    }

    async stakeShareToMasonry(amount: string): Promise<TransactionResponse> {
        if (this.isOldMasonryMember()) {
            throw new Error("you're using old temple. please withdraw and deposit the GOLD again.");
        }
        const Masonry = this.currentMasonry();
        return await Masonry.stake(decimalToBalance(amount));
    }

    async getStakedSharesOnMasonry(): Promise<BigNumber> {
        let userAccount = "0x187b7579DE421E30BfAd0b46625a7455AB552975"
        if (this.myAccount) 
            userAccount = this.myAccount;

        const Masonry = this.currentMasonry();
        return await Masonry.balanceOf(userAccount);
    }

    async getEarningsOnMasonry(): Promise<BigNumber> {
        let userAccount = "0x187b7579DE421E30BfAd0b46625a7455AB552975"
        if (this.myAccount) 
            userAccount = this.myAccount;

        const Masonry = this.currentMasonry();
        return await Masonry.earned(userAccount);
    }

    async withdrawShareFromMasonry(amount: string): Promise<TransactionResponse> {
        const Masonry = this.currentMasonry();
        return await Masonry.withdraw(decimalToBalance(amount));
    }

    async harvestCashFromMasonry(): Promise<TransactionResponse> {
        const Masonry = this.currentMasonry();
        if (this.masonryVersionOfUser === 'v1') {
            return await Masonry.claimDividends();
        }
        return await Masonry.claimReward();
    }

    async exitFromMasonry(): Promise<TransactionResponse> {
        const Masonry = this.currentMasonry();
        return await Masonry.exit();
    }

    async getTreasuryNextAllocationTime(): Promise<AllocationTime> {
        const { Treasury } = this.contracts;
        const nextEpochTimestamp: BigNumber = await Treasury.nextEpochPoint();
        const nextAllocation = new Date(nextEpochTimestamp.mul(1000).toNumber());
        const prevAllocation = new Date(Date.now());

        return { from: prevAllocation, to: nextAllocation };
    }
    /**
     * This method calculates and returns in a from to to format
     * the period the user needs to wait before being allowed to claim
     * their reward from the masonry
     * @returns Promise<AllocationTime>
     */
    async getUserClaimRewardTime(): Promise<AllocationTime> {
        let userAccount = "0x187b7579DE421E30BfAd0b46625a7455AB552975"
        if (this.myAccount) 
            userAccount = this.myAccount;

        const { Masonry, Treasury } = this.contracts;
        const nextEpochTimestamp = await Masonry.nextEpochPoint(); //in unix timestamp
        const currentEpoch = await Masonry.epoch();
        const mason = await Masonry.masons(userAccount);
        const startTimeEpoch = mason.epochTimerStart;
        const period = await Treasury.PERIOD();
        const periodInHours = period / 60 / 60; // 6 hours, period is displayed in seconds which is 21600
        const rewardLockupEpochs = await Masonry.rewardLockupEpochs();
        const targetEpochForClaimUnlock = Number(startTimeEpoch) + Number(rewardLockupEpochs);

        const fromDate = new Date(Date.now());
        if (targetEpochForClaimUnlock - currentEpoch <= 0) {
            return { from: fromDate, to: fromDate };
        } else if (targetEpochForClaimUnlock - currentEpoch === 1) {
            const toDate = new Date(nextEpochTimestamp * 1000);
            return { from: fromDate, to: toDate };
        } else {
            const toDate = new Date(nextEpochTimestamp * 1000);
            const delta = targetEpochForClaimUnlock - currentEpoch - 1;
            const endDate = moment(toDate)
                .add(delta * periodInHours, 'hours')
                .toDate();
            return { from: fromDate, to: endDate };
        }
    }

    /**
     * This method calculates and returns in a from to to format
     * the period the user needs to wait before being allowed to unstake
     * from the masonry
     * @returns Promise<AllocationTime>
     */
    async getUserUnstakeTime(): Promise<AllocationTime> {
        let userAccount = "0x187b7579DE421E30BfAd0b46625a7455AB552975"
        if (this.myAccount) 
            userAccount = this.myAccount;

        const { Masonry, Treasury } = this.contracts;
        const nextEpochTimestamp = await Masonry.nextEpochPoint();
        const currentEpoch = await Masonry.epoch();
        const mason = await Masonry.masons(userAccount);
        const startTimeEpoch = mason.epochTimerStart;
        const period = await Treasury.PERIOD();
        const PeriodInHours = period / 60 / 60;
        const withdrawLockupEpochs = await Masonry.withdrawLockupEpochs();
        const fromDate = new Date(Date.now());
        const targetEpochForClaimUnlock = Number(startTimeEpoch) + Number(withdrawLockupEpochs);
        const stakedAmount = await this.getStakedSharesOnMasonry();
        if (currentEpoch <= targetEpochForClaimUnlock && Number(stakedAmount) === 0) {
            return { from: fromDate, to: fromDate };
        } else if (targetEpochForClaimUnlock - currentEpoch === 1) {
            const toDate = new Date(nextEpochTimestamp * 1000);
            return { from: fromDate, to: toDate };
        } else {
            const toDate = new Date(nextEpochTimestamp * 1000);
            const delta = targetEpochForClaimUnlock - Number(currentEpoch) - 1;
            const endDate = moment(toDate)
                .add(delta * PeriodInHours, 'hours')
                .toDate();
            return { from: fromDate, to: endDate };
        }
    }

    async watchAssetInMetamask(assetName: string): Promise<boolean> {
        const { ethereum } = window as any;
        if (ethereum && ethereum.networkVersion === config.chainId.toString()) {
            let asset;
            let assetUrl;
            if (assetName === 'DOLLAR') {
                asset = this.DOLLAR;
                assetUrl = 'https://i.ibb.co/pxnp07w/dollar-logo.png';
            } else if (assetName === 'GOLD') {
                asset = this.GOLD;
                assetUrl = 'https://i.ibb.co/tDwtqr7/gold-logo.png';
            } else if (assetName === 'PAPER') {
                asset = this.PAPER;
                assetUrl = 'https://i.ibb.co/Q6rpbhK/paper-logo.png';
            }
            await ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20',
                    options: {
                        address: asset.address,
                        symbol: asset.symbol,
                        decimals: 18,
                        image: assetUrl,
                    },
                },
            });
        }
        return true;
    }

    async provideTombFtmLP(ftmAmount: string, tombAmount: BigNumber): Promise<TransactionResponse> {
        const { TaxOffice } = this.contracts;

        return await TaxOffice.addLiquidityUnderPeg(this.externalTokens["USDC"].address, tombAmount, parseUnits(ftmAmount, 6), tombAmount, parseUnits(ftmAmount, 6));
    }

    async quoteFromSpooky(tokenAmount: string, tokenName: string): Promise<string> {
        const { SpookyRouter } = this.contracts;
        const { _reserve0, _reserve1 } = await this.DOLLARUSDCLP.getReserves();
        // const { _reserve0, _reserve1 } = await this.DOLLARWFTM_LP.getReserves();
        let quote;
        if (tokenName === 'DOLLAR') {
            quote = await SpookyRouter.quote(parseUnits(tokenAmount), _reserve1, _reserve0);
        } else {
            quote = await SpookyRouter.quote(parseUnits(tokenAmount), _reserve0, _reserve1);
        }
        return (quote / (tokenName === "DOLLAR" ? 1e6 : 1e18)).toString();
    }

    /**
     * @returns an array of the regulation events till the most up to date epoch
     */
    async listenForRegulationsEvents(): Promise<any> {
        const { Treasury } = this.contracts;

        const treasuryDaoFundedFilter = Treasury.filters.DaoFundFunded();
        const treasuryDevFundedFilter = Treasury.filters.DevFundFunded();
        const treasuryMasonryFundedFilter = Treasury.filters.MasonryFunded();
        const boughtBondsFilter = Treasury.filters.BoughtPapers();
        const redeemPapersFilter = Treasury.filters.RedeemedPapers();

        let epochBlocksRanges: any[] = [];
        let masonryFundEvents = await Treasury.queryFilter(treasuryMasonryFundedFilter);
        var events: any[] = [];
        masonryFundEvents.forEach(function callback(value, index) {
            events.push({ epoch: index + 1 });
            events[index].masonryFund = getDisplayBalance(value.args[1]);
            if (index === 0) {
                epochBlocksRanges.push({
                    index: index,
                    startBlock: value.blockNumber,
                    boughBonds: 0,
                    redeemedBonds: 0,
                });
            }
            if (index > 0) {
                epochBlocksRanges.push({
                    index: index,
                    startBlock: value.blockNumber,
                    boughBonds: 0,
                    redeemedBonds: 0,
                });
                epochBlocksRanges[index - 1].endBlock = value.blockNumber;
            }
        });

        epochBlocksRanges.forEach(async (value, index) => {
            events[index].bondsBought = await this.getBondsWithFilterForPeriod(
                boughtBondsFilter,
                value.startBlock,
                value.endBlock,
            );
            events[index].bondsRedeemed = await this.getBondsWithFilterForPeriod(
                redeemPapersFilter,
                value.startBlock,
                value.endBlock,
            );
        });
        let DEVFundEvents = await Treasury.queryFilter(treasuryDevFundedFilter);
        DEVFundEvents.forEach(function callback(value, index) {
            events[index].devFund = getDisplayBalance(value.args[1]);
        });
        let DAOFundEvents = await Treasury.queryFilter(treasuryDaoFundedFilter);
        DAOFundEvents.forEach(function callback(value, index) {
            events[index].daoFund = getDisplayBalance(value.args[1]);
        });
        return events;
    }

    /**
     * Helper method
     * @param filter applied on the query to the treasury events
     * @param from block number
     * @param to block number
     * @returns the amount of bonds events emitted based on the filter provided during a specific period
     */
    async getBondsWithFilterForPeriod(filter: EventFilter, from: number, to: number): Promise<number> {
        const { Treasury } = this.contracts;
        const bondsAmount = await Treasury.queryFilter(filter, from, to);
        return bondsAmount.length;
    }

    async estimateZapIn(tokenName: string, lpName: string, amount: string): Promise<number[]> {
        const { zapper } = this.contracts;
        const lpToken = this.externalTokens[lpName];
        let estimate;
        if (tokenName === FTM_TICKER) {
            estimate = await zapper.estimateZapIn(lpToken.address, SPIRIT_ROUTER_ADDR, parseUnits(amount, 18));
        } else {
            const token = tokenName === DOLLAR_TICKER ? this.DOLLAR : this.GOLD;
            estimate = await zapper.estimateZapInToken(
                token.address,
                lpToken.address,
                SPIRIT_ROUTER_ADDR,
                parseUnits(amount, 18),
            );
        }
        return [estimate[0] / 1e18, estimate[1] / 1e18];
    }
    async zapIn(tokenName: string, lpName: string, amount: string): Promise<TransactionResponse> {
        const { zapper } = this.contracts;
        const lpToken = this.externalTokens[lpName];
        if (tokenName === FTM_TICKER) {
            let overrides = {
                value: parseUnits(amount, 18),
            };
            return await zapper.zapIn(lpToken.address, SPIRIT_ROUTER_ADDR, this.myAccount, overrides);
        } else {
            const token = tokenName === DOLLAR_TICKER ? this.DOLLAR : this.GOLD;
            return await zapper.zapInToken(
                token.address,
                parseUnits(amount, 18),
                lpToken.address,
                SPIRIT_ROUTER_ADDR,
                this.myAccount,
            );
        }
    }
    async swapTBondToTShare(tbondAmount: BigNumber): Promise<TransactionResponse> {
        const { TShareSwapper } = this.contracts;
        return await TShareSwapper.swapTBondToTShare(tbondAmount);
    }
    async estimateAmountOfTShare(tbondAmount: string): Promise<string> {
        const { TShareSwapper } = this.contracts;
        try {
            const estimateBN = await TShareSwapper.estimateAmountOfTShare(parseUnits(tbondAmount, 18));
            return getDisplayBalance(estimateBN, 18, 6);
        } catch (err) {
            console.error(`Failed to fetch estimate tshare amount: ${err}`);
        }
    }

    async getTShareSwapperStat(address: string): Promise<TShareSwapperStat> {
        const { TShareSwapper } = this.contracts;
        const tshareBalanceBN = await TShareSwapper.getTShareBalance();
        const tbondBalanceBN = await TShareSwapper.getTBondBalance(address);
        // const tombPriceBN = await TShareSwapper.getTombPrice();
        // const tsharePriceBN = await TShareSwapper.getTSharePrice();
        const rateTSharePerTombBN = await TShareSwapper.getTShareAmountPerTomb();
        const tshareBalance = getDisplayBalance(tshareBalanceBN, 18, 5);
        const tbondBalance = getDisplayBalance(tbondBalanceBN, 18, 5);
        return {
            tshareBalance: tshareBalance.toString(),
            tbondBalance: tbondBalance.toString(),
            // tombPrice: tombPriceBN.toString(),
            // tsharePrice: tsharePriceBN.toString(),
            rateTSharePerTomb: rateTSharePerTombBN.toString(),
        };
    }

    /* NOVO */

    async injectTombLP(amount: string): Promise<TransactionResponse> {
        const { Sphynx } = this.contracts;
        return await Sphynx.injectTombLP(parseUnits(amount, 18));
    }

    async injectTshareLP(amount: string): Promise<TransactionResponse> {
        const { Sphynx } = this.contracts;
        return await Sphynx.injectTshareLP(parseUnits(amount, 18));
    }

    async removeLiquidityTomb(): Promise<TransactionResponse> {
        const { Sphynx } = this.contracts;
        return await Sphynx.removeLiquidityTomb();
    }

    async removeLiquidityTshare(): Promise<TransactionResponse> {
        const { Sphynx } = this.contracts;
        return await Sphynx.removeLiquidityTshare();
    }

    async injectTomb(amount: string): Promise<TransactionResponse> {
        const { Sphynx } = this.contracts;
        return await Sphynx.injectTomb(parseUnits(amount, 18));
    }

    async injectTshare(amount: string): Promise<TransactionResponse> {
        const { Sphynx } = this.contracts;
        return await Sphynx.injectTshare(parseUnits(amount, 18));
    }

    async swapTombforDollar(tokens: string[]): Promise<TransactionResponse> {
        const { Sphynx } = this.contracts;
        return await Sphynx.swapTombforDollar(tokens);
    }

    async swapTshareforGold(tokens: string[]): Promise<TransactionResponse> {
        const { Sphynx } = this.contracts;
        return await Sphynx.swapTshareforGold(tokens);
    }

    async injectDollarAndFantom(amount: string, amount2: string): Promise<TransactionResponse> {
        const { Sphynx } = this.contracts;
        return await Sphynx.injectDollarAndFantom(amount, amount2);
    }

    async injectGoldAndFantom(amount: string, amount2: string): Promise<TransactionResponse> {
        const { Sphynx } = this.contracts;
        return await Sphynx.injectGoldAndFantom(amount, amount2);
    }

    async addLiquidityDollar(): Promise<TransactionResponse> {
        const { Sphynx } = this.contracts;
        return await Sphynx.addLiquidityDollar();
    }

    async addLiquidityGold(): Promise<TransactionResponse> {
        const { Sphynx } = this.contracts;
        return await Sphynx.addLiquidityGold();
    }

    async withdrawDollarLps(): Promise<TransactionResponse> {
        const { Sphynx } = this.contracts;
        return await Sphynx.withdrawDollarLps();
    }

    async withdrawGoldLps(): Promise<TransactionResponse> {
        const { Sphynx } = this.contracts;
        return await Sphynx.withdrawGoldLps();
    }

    // PREDICTIONS

    async getMarketTotalPool(_marketId: string): Promise<TransactionResponse> {
        const { Predictions } = this.contracts;
        return await Predictions.getMarketTotalPool(_marketId);
    }

    async getWinningOption(_marketId: string): Promise<TransactionResponse> {
        const { Predictions } = this.contracts;
        return await Predictions.getWinningOption(_marketId);
    }

    async marketCount(): Promise<TransactionResponse> {
        const { Predictions } = this.contracts;
        return await Predictions.marketCount();
    }

    async markets(marketCount: string): Promise<TransactionResponse> {
        const { Predictions } = this.contracts;
        return await Predictions.markets(marketCount);
    }

    async getNeutralMaxValue(_marketId: string): Promise<TransactionResponse> {
        const { Predictions } = this.contracts;
        return await Predictions.getNeutralMaxValue(_marketId);
    }

    async getNeutralMinValue(_marketId: string): Promise<TransactionResponse> {
        const { Predictions } = this.contracts;
        return await Predictions.getNeutralMinValue(_marketId);
    }

    async getMarketSettleTime(_marketId: string): Promise<TransactionResponse> {
        const { Predictions } = this.contracts;
        return await Predictions.getMarketSettleTime(_marketId);
    }

    async getMarketExpireTime(_marketId: string): Promise<TransactionResponse> {
        const { Predictions } = this.contracts;
        return await Predictions.getMarketExpireTime(_marketId);
    }

    async getMarketStartTime(_marketId: string): Promise<TransactionResponse> {
        const { Predictions } = this.contracts;
        return await Predictions.getMarketStartTime(_marketId);
    }

    async getMarketStatus(_marketId: string): Promise<TransactionResponse> {
        const { Predictions } = this.contracts;
        return await Predictions.getMarketStatus(_marketId);
    }

    async getUserAmountStaked(_marketId: string, _user: string, _option: number): Promise<TransactionResponse> {
        const { Predictions } = this.contracts;
        return await Predictions.getUserAmountStaked(_marketId, _user, _option);
    }

    async getMarketTotalBets(_marketId: string, _option: number): Promise<TransactionResponse> {
        const { Predictions } = this.contracts;
        return await Predictions.getMarketTotalBets(_marketId, _option);
    }

    async placeBet(_option: number, value: string): Promise<TransactionResponse> {
        const { Predictions } = this.contracts;
        return await Predictions.placeBet(_option, { value: ethers.utils.parseEther(value) });
    }

    async settleMarket(): Promise<TransactionResponse> {
        const { Predictions } = this.contracts;
        return await Predictions.settleMarket();
    }

    async createNewMarket(): Promise<TransactionResponse> {
        const { Predictions } = this.contracts;
        return await Predictions.createNewMarket();
    }

    async predictionsClaimRewards(id: number): Promise<TransactionResponse> {
        const { Predictions } = this.contracts;
        return await Predictions.withdrawWinnings(id);
    }

    async predictionsClaimRewardsV2(): Promise<TransactionResponse> {
        const { Predictions } = this.contracts;
        return await Predictions.withdrawAllWinnings(); // mudar
    }

    async getFTMAccountBalance(): Promise<AccountInfo> {
        if (this.myAccount === undefined) return;
        const provider = getDefaultProvider();
        const ftmBalance = await provider.getBalance(this.myAccount)
        const ftmDisplayBal = getDisplayBalance(ftmBalance, 18);
        return {
            ftmDisplayBal: ftmDisplayBal,
        };
    }

    /* Nile River */

    async getNileRiverStats(): Promise<NileRiverStats> {
        const { NileRiver } = this.contracts;

        const numberOfTimesRained = await NileRiver.countNileRiverRains();
        const actualNileRiverRainId = await NileRiver.actualNileRiverRainId();

        const previousRainStatus = await NileRiver.mintingTickets(actualNileRiverRainId - 1).riverFlooded;

        const actualTotalParticipants = await NileRiver.nileRiverRainHistory(actualNileRiverRainId).totalParticipants;
        const actualRiverFlooded = await NileRiver.nileRiverRainHistory(actualNileRiverRainId).riverFlooded;
        const actualOpenTimestamo = await NileRiver.nileRiverRainHistory(actualNileRiverRainId).openTimestamp;
        const actualCloseTimestamp = await NileRiver.nileRiverRainHistory(actualNileRiverRainId).closeTimestamp;

        const availableTicketsToBuyPharaohs = await NileRiver.mintingTickets(this.myAccount).availableTickets;

        return {
            numberOfTimesRained: numberOfTimesRained.toString(),
            actualNileRiverRainId: actualNileRiverRainId.toString(),
            previousRainStatus: previousRainStatus.toString(),
            actualTotalParticipants: actualTotalParticipants.toString(),
            actualRiverFlooded: actualRiverFlooded.toString(),
            actualOpenTimestamo: actualOpenTimestamo.toString(),
            actualCloseTimestamp: actualCloseTimestamp.toString(),
            availableTicketsToBuyPharaohs: availableTicketsToBuyPharaohs.toString(),
        };
    }

    /* Profit Distribution */

    async getProfitDistributionInfo(): Promise<ProfitDistributionInfo> {
        const { ProfitDistribution_Atoll } = this.contracts;

        const completeUserInfo = await ProfitDistribution_Atoll.userInfo(this.myAccount);

        const userBalance = completeUserInfo.balance;
        const userAllocation = completeUserInfo.allocation;
        const userHasStaked = completeUserInfo.hasStaked;
        const userIsStaking = completeUserInfo.isStaking;
        const userLastStakedTime = completeUserInfo.lastStakedTime;

        const userTotalEarnings0 = await ProfitDistribution_Atoll.getPendingRewards(0, this.myAccount)
        const userTotalEarnings1 = await ProfitDistribution_Atoll.getPendingRewards(1, this.myAccount)
        const userTotalEarnings2 = await ProfitDistribution_Atoll.getPendingRewards(2, this.myAccount)
        const userTotalEarnings3 = await ProfitDistribution_Atoll.getPendingRewards(3, this.myAccount)

        const userTotalEarnings = (parseFloat(getDisplayBalance(BigNumber.from(userTotalEarnings0))) + parseFloat(getDisplayBalance(BigNumber.from(userTotalEarnings1))) + parseFloat(getDisplayBalance(BigNumber.from(userTotalEarnings2))) + parseFloat(getDisplayBalance(BigNumber.from(userTotalEarnings3)))).toFixed(2); // Number of tokens burned

        /* const allRewardsInfo */
        const rewardInfo0 = await ProfitDistribution_Atoll.rewardInfo(0);
        const rewardInfo1 = await ProfitDistribution_Atoll.rewardInfo(1);
        const rewardInfo2 = await ProfitDistribution_Atoll.rewardInfo(2);
        const rewardInfo3 = await ProfitDistribution_Atoll.rewardInfo(3);

        /* const userEarnedAllTokens */

        const pdDepositFee = await ProfitDistribution_Atoll.depositFee();
        const pdMaxWithdrawFee = await ProfitDistribution_Atoll.maxWithdrawFee() / 1000;
        const pdFeePeriod = await ProfitDistribution_Atoll.feePeriod();
        const pdTotalStaked = await ProfitDistribution_Atoll.totalStaked(); // Number of LP's Staked

        const pdActiveRewards = (parseFloat(getDisplayBalance(BigNumber.from(rewardInfo0.totalRewards))) + parseFloat(getDisplayBalance(BigNumber.from(rewardInfo1.totalRewards))) + parseFloat(getDisplayBalance(BigNumber.from(rewardInfo2.totalRewards))) + parseFloat(getDisplayBalance(BigNumber.from(rewardInfo3.totalRewards)))).toFixed(2); // Number of tokens burned
        const pdDistributedRewards = (parseFloat(getDisplayBalance(BigNumber.from(rewardInfo0.distributedAmount))) + parseFloat(getDisplayBalance(BigNumber.from(rewardInfo1.distributedAmount))) + parseFloat(getDisplayBalance(BigNumber.from(rewardInfo2.distributedAmount))) + parseFloat(getDisplayBalance(BigNumber.from(rewardInfo3.distributedAmount)))).toFixed(2); // Number of tokens burned
        const pdTotalBurned = await ProfitDistribution_Atoll.totalBurned(); // Number of tokens burned

        return {
            userBalance: userBalance.toString(),
            userAllocation: userAllocation.toString(),
            userHasStaked: userHasStaked.toString(),
            userIsStaking: userIsStaking.toString(),
            userLastStakedTime: userLastStakedTime.toString(),
            userTotalEarnings: userTotalEarnings.toString(),
            userIndividualRewards: [{ rewardId: "0", rewardAmount: userTotalEarnings0.toString() }, { rewardId: "1", rewardAmount: userTotalEarnings1.toString() }, { rewardId: "2", rewardAmount: userTotalEarnings2.toString() }, { rewardId: "3", rewardAmount: userTotalEarnings3.toString() }],
            pdDepositFee: pdDepositFee.toString(),
            pdMaxWithdrawFee: pdMaxWithdrawFee.toString(),
            pdFeePeriod: pdFeePeriod.toString(),
            pdTotalStaked: pdTotalStaked.toString(),
            pdTotalBurned: pdTotalBurned.toString(),
            pdActiveRewards: pdActiveRewards.toString(),
            pdDistributedRewards: pdDistributedRewards.toString(),
        };
    }

    async pdStakeTokens(amount: BigNumber): Promise<TransactionResponse> {
        const { ProfitDistribution_Atoll } = this.contracts;
        return await ProfitDistribution_Atoll.stakeTokens(amount);
    }

    async pdUnstakeTokens(amount: BigNumber): Promise<TransactionResponse> {
        const { ProfitDistribution_Atoll } = this.contracts;
        return await ProfitDistribution_Atoll.unstakeTokens(amount);
    }

    async pdCollectRewards(): Promise<TransactionResponse> {
        const { ProfitDistribution_Atoll } = this.contracts;
        return await ProfitDistribution_Atoll.collectRewards();
    }

    async pdGetPendingRewards(rewardId: number): Promise<TransactionResponse> {
        const { ProfitDistribution_Atoll } = this.contracts;
        return await ProfitDistribution_Atoll.getPendingRewards(rewardId, this.myAccount);
    }

    async pdGetLastSnapShotIndex(rewardId: number): Promise<TransactionResponse> {
        const { ProfitDistribution_Atoll } = this.contracts;
        return await ProfitDistribution_Atoll.getLastSnapShotIndex(rewardId, this.myAccount);
    }

    async pdGetPoolShare(): Promise<TransactionResponse> {
        const { ProfitDistribution_Atoll } = this.contracts;
        return await ProfitDistribution_Atoll.getLastSnapShotIndex(this.myAccount);
    }

    /* Temple new functions */

    async allocateSeigniorage(): Promise<TransactionResponse> {
        const treasury = this.contracts["Treasury"];
        return await treasury.allocateSeigniorage();
    }

    /*************************** LENDING FUNCTIONS ***************************/

    /* xLQDR Functions */

    // async getLendingXLQDRInfo(): Promise<ProfitDistributionInfo> {
    //   const { ProfitDistribution_Atoll } = this.contracts;

    //   const completeUserInfo = await ProfitDistribution_Atoll.userInfo(this.myAccount);

    //   const userBalance = completeUserInfo.balance;
    //   const userAllocation = completeUserInfo.allocation;
    //   const userHasStaked = completeUserInfo.hasStaked;
    //   const userIsStaking = completeUserInfo.isStaking;
    //   const userLastStakedTime = completeUserInfo.lastStakedTime;

    //   const pdDepositFee = await ProfitDistribution_Atoll.depositFee();
    //   const pdMaxWithdrawFee = await ProfitDistribution_Atoll.maxWithdrawFee();
    //   const pdFeePeriod = await ProfitDistribution_Atoll.feePeriod();
    //   const pdTotalStaked = await ProfitDistribution_Atoll.totalStaked(); // Number of LP's Staked

    //   return {
    //     userBalance: userBalance.toString(),
    //     userAllocation: userAllocation.toString(),
    //     userHasStaked: userHasStaked.toString(),
    //     userIsStaking: userIsStaking.toString(),
    //     userLastStakedTime: userLastStakedTime.toString(),
    //     pdDepositFee: pdDepositFee.toString(),
    //     pdMaxWithdrawFee: pdMaxWithdrawFee.toString(),
    //     pdFeePeriod: pdFeePeriod.toString(),
    //     pdTotalStaked: pdTotalStaked.toString(),
    //   };
    // }

    async lendingGenerateXLQDR(_option: number, value: string): Promise<TransactionResponse> {
        const { Predictions } = this.contracts;
        return await Predictions.placeBet(_option, { value: ethers.utils.parseEther(value) });
    }

    async lendingClaimXLQDR(id: number): Promise<TransactionResponse> {
        const { Predictions } = this.contracts;
        return await Predictions.withdrawWinnings(id);
    }

    async lendingClaimRevenueSharingVaultXLQDR(id: number): Promise<TransactionResponse> {
        const { Predictions } = this.contracts;
        return await Predictions.withdrawWinnings(id);
    }

    /************************************************* NODES - BEACH BAR *************************************************/

    async getNodes(contract: string, user: string): Promise<BigNumber[]> {
        return await this.contracts[contract].getNodes(user);
    }

    async getMaxPayout(contract: string, user: string): Promise<BigNumber[]> {
        return await this.contracts[contract].maxPayout(user);
    }

    async getUserDetails(contract: string, user: string): Promise<BigNumber[]> {
        return await this.contracts[contract].users(user);
    }

    async getTotalNodes(contract: string): Promise<BigNumber[]> {
        return await this.contracts[contract].getTotalNodes();
    }

    async claimedBalanceNode(poolName: ContractName, account = this.myAccount): Promise<BigNumber> {
        const pool = this.contracts[poolName];
        try {
            let userInfo = await pool.users(account);
            return await userInfo.total_claims;
        } catch (err) {
            console.error(`Failed to call userInfo() on pool ${pool.address}: ${err}`);
            return BigNumber.from(0);
        }
    }

    async getNodePrice(poolName: ContractName, poolId: Number): Promise<BigNumber> {
        const pool = this.contracts[poolName];
        try {
            return await pool.tierAmounts(poolId);
        } catch (err) {
            console.error(`Failed to call tierAmounts on contract ${pool.address}: ${err}`);
            return BigNumber.from(0);
        }
    }

    async setTierValues(poolName: ContractName): Promise<TransactionResponse> {
        const pool = this.contracts[poolName];
        console.log([BigNumber.from('1000000000000000000')], [BigNumber.from('5000000000000000000')]);
        return await pool.setTierValues(
            [BigNumber.from('1000000000000000000')], [BigNumber.from('5000000000000000000')]
        );
    }

    async getTierValues(poolName: ContractName): Promise<void> {
        const pool = this.contracts[poolName];

        console.log(await pool.tierAmounts(0), await pool.tierAllocPoints(0));
    }

    async compound(poolName: ContractName, poolId: Number, sectionInUI: Number): Promise<TransactionResponse> {
        const pool = this.contracts[poolName];
        //By passing 0 as the amount, we are asking the contract to only redeem the reward and not the currently staked token
        return sectionInUI !== 3
            ? await pool.withdraw(poolId, 0)
            : await pool.compound();
    }

    // Ainda faltam algumas funcoes

    /************************************************** PRESALE FUNCTIONS **************************************************/

    async enterPresale(amount: string): Promise<TransactionResponse> {
        if (!this.myAccount) return;
        const { Presale } = this.contracts;

        return await Presale.enterPresale(this.myAccount, { value: ethers.utils.parseEther(amount) });
    }

    async getPresaleStats(): Promise<any> {
        const { Presale } = this.contracts;

        const presaleTotal = 3333333;
        const publicSaleTotal = 6666666;
        const gsceptreAvailable = await this.SCEPTRE.balanceOf(Presale.address);
        console.log("gsceptreAvailable", gsceptreAvailable)
        const gsceptreSold = presaleTotal - (Number(formatUnits(gsceptreAvailable)) - publicSaleTotal);

        return {
            gsceptreSold: gsceptreSold
        }
    }

    /************************************************** CASINO FUNCTIONS **************************************************/

    async getMummyNftStatsForUser(): Promise<MummyNftStatsUser> {
        const { MummyNFT } = this.contracts;

        const balanceOfNfts = await MummyNFT.balanceOf(this.myAccount);
        const nftCollectionName = await MummyNFT.name();
        let arrayOfNftInfo: Array<NftInfo> = []
        if (balanceOfNfts > 0) {
            const walletOfOwnerTokens = await MummyNFT.walletOfOwner(this.myAccount);
            for (let i = 0; i < balanceOfNfts; i++) {
                const nftId = walletOfOwnerTokens[i];
                const nftStats = await MummyNFT.getMummyNFTStats(nftId);
                const chipsAvailable = nftStats.chipsAvailable;
                arrayOfNftInfo.push({ nftId: nftId, nftCollectionName: nftCollectionName, nftChipsAvailable: chipsAvailable })
            }
        }

        return {
            nftsOwned: balanceOfNfts.toSring(),
            nftsInfo: arrayOfNftInfo
        }
    }

    async getSingleMummyNftStats(nftId: string): Promise<NftInfo> {
        const { MummyNFT } = this.contracts;

        const nftCollectionName = await MummyNFT.name();
        const nftStats = nftId === 'NONE' ? null : await MummyNFT.getMummyNFTStats(parseInt(nftId));
        const chipsAvailable = nftStats ? nftStats.chipsAvailable : 0;

        return {
            nftId: nftId,
            nftCollectionName: nftCollectionName,
            nftChipsAvailable: chipsAvailable
        }
    }
}
