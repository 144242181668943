import { createContext } from 'react';
import { LendingPool } from '../../tomb-finance';

export interface LendingPoolsContext {
  lendingPools: LendingPool[];
}

const context = createContext<LendingPoolsContext>({
  lendingPools: [],
});

export default context;
